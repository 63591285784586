import React, { FC } from 'react';
import { css } from 'styled-components';

import { useTranslation } from 'crehana-i18n';
import { Col } from 'crehana-ui/GridV2';
import CrehanaLogo from 'crehana-icons/CrehanaLogo';

interface CopyrightProps {
  isDark?: boolean;
  className: string;
}

const Copyright: FC<CopyrightProps> = ({ isDark, className }) => {
  const { t } = useTranslation('main-footer');

  return (
    <Col
      md={8}
      lg={6}
      nowrap
      className={`${className} text-center md:text-left mt-24 sm:mt-48 lg:mt-0`}
    >
      <span
        className={`block sm:inline-block mb-20 sm:mb-0 ${
          isDark ? 'text-white' : 'text-primary-main'
        }`}
      >
        <CrehanaLogo size={24} ignoreColor className="sm:mr-32 fill-current" />
      </span>
      <span
        className={`font-legals ${
          isDark ? 'text-base-lighter' : 'text-gray-dark'
        }`}
        css={css`
          ${props => props.theme.breakpoints.down('sm')} {
            white-space: pre-line;
          }
        `}
      >
        Copyright &#169; Crehana {new Date().getFullYear()} -{' '}
        {t('RIGHTS_RESERVED')}
      </span>
    </Col>
  );
};

export default Copyright;
