import { I18nTranslation, Namespaces, Locales, TConfig } from './types';

const isObject = <T>(obj?: T) => obj && typeof obj === 'object';

export function mergeTranslations(
  target: Record<string, any>,
  source: Record<string, any>,
): I18nTranslation {
  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  const _target = { ...target };
  const keys = Object.keys(source);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const targetValue = _target[key];
    const sourceValue = source[key];

    if (isObject(targetValue) && isObject(sourceValue)) {
      _target[key] = mergeTranslations({ ...targetValue }, sourceValue);
    } else {
      _target[key] = sourceValue;
    }
  }

  return _target;
}

export const genMemoKey = (language: string, key: string, namespace?: string) =>
  `${language}${namespace ? `:${namespace}` : ''}:${key}`;

function validateConfig(key: string, config?: TConfig) {
  if (typeof config === 'object' && config.defaultValue?.length === 0) {
    console.error(`defaultValue must not be an empty string for key: ${key}`);
  } else if (typeof config === 'object' && config.namespace?.length === 0) {
    console.error(`namespace must not be an empty string for key: ${key}`);
  }
}

const interpolationRegex = /\{\{(.+?)\}\}/g;

function getInterpolation(
  value: string,
  replacements: { [key: string]: unknown },
) {
  return value.replace(interpolationRegex, (_, name) => {
    const replacement = replacements[name] as string | undefined;

    if (replacement === undefined || replacement === null) {
      if (process.env.NODE_ENV === 'development') {
        console.error(`Invalid interpolation value "${name}"`);
      }
      return '';
    }
    return replacement;
  });
}

function getValueByKey(
  key: string,
  pluralKey: string | undefined,
  keys: Locales,
): string | undefined {
  return pluralKey ? keys[pluralKey] || keys[key] : keys[key];
}

function getTranslation(
  key: string,
  nss: Namespaces,
  pluralKey?: string,
  namespace?: string,
  fallbackNs?: string[],
) {
  let value: string | undefined;

  if (namespace) {
    const keys = nss[namespace];

    if (keys) {
      value = getValueByKey(key, pluralKey, keys);
    }
  }

  if (!value && fallbackNs?.length) {
    const fb = fallbackNs.find(
      n => nss[n] && getValueByKey(key, pluralKey, nss[n]!),
    );

    if (fb && nss[fb]) {
      value = getValueByKey(key, pluralKey, nss[fb]!);
    }
  }

  return value;
}

export function getTranslationValue(
  key: string,
  language: string,
  translations: I18nTranslation,
  config?: TConfig,
): string {
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'test'
  ) {
    if (!key.length) {
      console.error('key must not be an empty string');
    }
    validateConfig(key, config);
  }

  const {
    defaultValue,
    interpolation,
    namespace,
    fallbackLanguageKey,
    fallbackNs,
  } = config || {};
  const pluralKey =
    interpolation?.count && (interpolation.count as number) > 1
      ? `${key}_plural`
      : undefined;

  const nss = translations[language];
  let value: string | undefined;

  if (nss) {
    value = getTranslation(key, nss, pluralKey, namespace, fallbackNs);
  }

  if (!value && fallbackLanguageKey) {
    const nss2 = translations[fallbackLanguageKey];

    if (nss2) {
      value = getTranslation(key, nss2, pluralKey, namespace, fallbackNs);
    }
  }

  if (process.env.NODE_ENV === 'development') {
    if (!value && !defaultValue) {
      console.error(
        `CrehanaI18n\nNo translation found for:
      - key: ${key}
      - plural: ${pluralKey}
      - language: ${language}
      - namespace: ${namespace || 'no namespace'}
      - defaultValue: ${defaultValue}
      `,
      );
    }
  }

  if (interpolation) {
    if (value) {
      return getInterpolation(value, interpolation);
    }

    if (defaultValue) {
      return getInterpolation(defaultValue, interpolation);
    }

    return key;
  }

  return value || defaultValue || key;
}

export const pipe = <T>(...fns: ((v: T) => T)[]) => (x: T) =>
  fns.reduce((v, f) => f(v), x);

// Public utils specific to crehana implementation
export type TranslationKeys = Array<{
  keys: Array<{ name: string; value: string }>;
  page: string;
  language: string;
}>;

export function transformInitialStateTranslations(
  translations: TranslationKeys,
): I18nTranslation {
  return translations.reduce(
    (acc, t) => ({
      ...acc,
      [t.language]: {
        ...acc[t.language],
        [t.page]: t.keys.reduce(
          (acc2, curr) => ({
            ...acc2,
            [curr.name]: curr.value,
          }),
          {},
        ),
      },
    }),
    {} as I18nTranslation,
  );
}
