/* eslint-disable react/no-danger */
import React, { ReactNode } from 'react';

const BOLD_REGEX = /\*\*(.+?)\*\*/g;
const ITALIC_REGEX = /\*(.+?)\*/g;

export function toJsx(text: string): ReactNode {
  return <span dangerouslySetInnerHTML={{ __html: text }} />;
}

export function boldFormatter(text: string): string {
  return text.replace(
    BOLD_REGEX,
    (_, replaced) => `<strong>${replaced}</strong>`,
  );
}

export function italicFormatter(text: string): string {
  return text.replace(ITALIC_REGEX, (_, replaced) => `<em>${replaced}</em>`);
}
