import React from 'react';
import Observer, {
  Props as ObserverProps,
} from '@researchgate/react-intersection-observer';

const Lazyload: React.FC<{
  children: React.ReactNode;
  style?: React.CSSProperties;
  placeholder?: React.ReactNode;
  wrapperClassName?: string;
  observerOptions?: {
    rootMargin: string;
  };
  onVisible?: () => void;
}> = ({
  children,
  style,
  placeholder,
  observerOptions,
  wrapperClassName,
  onVisible,
}) => {
  const [isVisbile, setIsVisible] = React.useState(false);

  const handleIntersection: ObserverProps['onChange'] = event => {
    const { isIntersecting } = event;

    if (isIntersecting) {
      setIsVisible(true);
      if (onVisible) {
        onVisible();
      }
    }
  };
  const options = {
    onChange: handleIntersection,
    rootMargin: '0px 0px 300px 0px',
    disabled: isVisbile,
  };

  return (
    <Observer {...options} {...observerOptions}>
      <div className={wrapperClassName} style={{ ...style }}>
        {isVisbile ? children : placeholder || <div />}
      </div>
    </Observer>
  );
};

export default Lazyload;
