import React from 'react';
import { IconProps } from './types';

const GooglePlayIcon: React.FC<IconProps> = ({
  className,
  size = 32,
  color = '#181B32',
  ignoreColor,
}) => (
  <svg
    className={className}
    width={size}
    height={size * 1.1}
    viewBox="0 0 17 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M.332.663A1.646 1.646 0 0 0 0 1.662v14.676c0 .344.103.668.288.938l7.986-8.33L.332.664zM9.005 8.184l2.59-2.702L2.49.225A1.64 1.64 0 0 0 1.218.06l7.787 8.123zM9.005 9.71l-7.866 8.204a1.633 1.633 0 0 0 1.35-.139l9.172-5.295-2.656-2.77zM15.199 7.563l-2.662-1.537-2.8 2.92 2.865 2.99 2.597-1.499c.52-.3.83-.837.83-1.437 0-.6-.31-1.137-.83-1.437z"
      fill={!ignoreColor ? color : undefined}
    />
  </svg>
);

export default GooglePlayIcon;
