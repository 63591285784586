const getCurrentLanguageKey = (params?: { defaultKey?: 'es' | 'pt' }) => {
  const defaultKey: 'es' | 'pt' = (params && params.defaultKey) || 'es';

  if (
    typeof window !== 'undefined' &&
    window.__INITIAL_STATE__ &&
    window.__INITIAL_STATE__.LANGUAGE_KEY
  ) {
    return window.__INITIAL_STATE__.LANGUAGE_KEY as 'es' | 'pt';
  }
  return defaultKey;
};

export default getCurrentLanguageKey;
