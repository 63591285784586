import { CuiScreens, CuiBreakpointsFn, BreakpointKeyType } from './types';

export const screens: CuiScreens = {
  sm: '768px',
  md: '1024px',
  lg: '1366px',
};

export const breakpoints: CuiBreakpointsFn = {
  up: key => {
    if (Object.hasOwnProperty.call(screens, key)) {
      return `@media (min-width: ${screens[key]})`;
    }
    return '';
  },
  down: key => {
    if (Object.hasOwnProperty.call(screens, key)) {
      return `@media (max-width: ${Number(screens[key].replace('px', '')) -
        1}px)`;
    }
    return '';
  },
};

export const isBreakpointKeyType = (key: string): key is BreakpointKeyType =>
  Object.keys(screens).includes(key);
