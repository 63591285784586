import React from 'react';
import { IconProps } from './types';

const Apple: React.FC<IconProps> = ({
  className,
  size = 32,
  color = '#181B32',
  ignoreColor,
}) => (
  <svg
    className={className}
    width={0.8125 * size}
    height={size}
    viewBox="0 0 13 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.6424,2.3684 C-0.6456,4.6024 0.1734,7.9934 1.5974,10.0494 C2.3084,11.0774 3.0274,12.0004 4.0134,12.0004 C4.0314,12.0004 4.0504,11.9994 4.0694,11.9984 C4.5324,11.9804 4.8674,11.8384 5.1904,11.7004 C5.5534,11.5454 5.9294,11.3854 6.5184,11.3854 C7.0794,11.3854 7.4364,11.5404 7.7834,11.6894 C8.1234,11.8374 8.4754,11.9904 8.9944,11.9804 C10.1044,11.9594 10.7864,10.9644 11.3874,10.0854 C12.0154,9.1684 12.3294,8.2784 12.4354,7.9374 L12.4404,7.9244 C12.4604,7.8644 12.4314,7.7974 12.3734,7.7714 C12.3714,7.7704 12.3664,7.7674 12.3644,7.7674 C12.1694,7.6874 10.4544,6.9264 10.4364,4.8534 C10.4194,3.1684 11.7224,2.2764 11.9844,2.1144 L11.9964,2.1074 C12.0244,2.0884 12.0444,2.0594 12.0514,2.0264 C12.0574,1.9934 12.0504,1.9594 12.0314,1.9314 C11.1324,0.6154 9.7534,0.4164 9.1984,0.3924 C9.1184,0.3854 9.0344,0.3814 8.9514,0.3814 C8.2994,0.3814 7.6744,0.6274 7.1724,0.8254 C6.8264,0.9614 6.5274,1.0794 6.3214,1.0794 C6.0894,1.0794 5.7884,0.9604 5.4394,0.8224 C4.9744,0.6374 4.4464,0.4274 3.8874,0.4274 C3.8734,0.4274 3.8604,0.4274 3.8474,0.4284 C2.5474,0.4474 1.3194,1.1904 0.6424,2.3684"
      fill={!ignoreColor ? color : undefined}
      transform="translate(0.000000, 4.230400)"
    />
    <path
      d="M3.1996,0.7697 C2.4126,0.8017 1.4686,1.2867 0.9036,1.9477 C0.4246,2.5027 -0.0444,3.4297 0.0786,4.3627 C0.0866,4.4217 0.1346,4.4667 0.1936,4.4717 C0.2456,4.4757 0.3006,4.4777 0.3546,4.4777 C1.1236,4.4777 1.9546,4.0517 2.5216,3.3657 C3.1176,2.6427 3.4196,1.7127 3.3286,0.8807 C3.3216,0.8157 3.2636,0.7677 3.1996,0.7697"
      fill={!ignoreColor ? color : undefined}
      transform="translate(6.000000, 0.230400)"
    />
  </svg>
);

export default Apple;
