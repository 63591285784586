import React from 'react';
import { IconProps } from './types';

const CrehanaLogo: React.FC<IconProps> = ({
  className,
  color = '#4B22F4',
  size = 32,
  ignoreColor,
}) => {
  const primary = !ignoreColor ? color : undefined;

  return (
    <svg
      className={className}
      width={size * 5.4375}
      height={size}
      viewBox="0 0 87 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.89409 12.958C6.43618 12.958 6.90927 12.8099 7.29367 12.5136C7.67806 12.2173 7.91462 11.8222 8.00332 11.3383H11.3939C11.2658 12.7308 10.6744 13.8568 9.63948 14.7161C8.63413 15.5753 7.38237 16 5.87436 16C4.17907 16 2.76963 15.4766 1.66572 14.4198C0.561812 13.3827 0 12.0296 0 10.3605C0 8.72098 0.551956 7.37776 1.66572 6.34072C2.78934 5.27405 4.18893 4.74072 5.87436 4.74072C7.3528 4.74072 8.60456 5.17531 9.63948 6.05432C10.6744 6.91358 11.2559 8.02962 11.3939 9.40246H8.00332C7.91462 8.91851 7.67806 8.52344 7.29367 8.22715C6.90927 7.93085 6.44604 7.78272 5.89409 7.78272C5.15486 7.78272 4.56349 8.01976 4.1101 8.49383C3.65671 8.96791 3.43001 9.59011 3.43001 10.3605C3.43001 11.1309 3.65671 11.7531 4.1101 12.237C4.55363 12.721 5.15486 12.958 5.89409 12.958Z"
        fill={primary}
      />
      <path
        d="M20.452 4.80981V8.22709C18.8454 8.28635 17.6035 8.74067 16.7559 9.58018V15.753H13.1484V4.98759H16.7559V6.14316C17.7809 5.25427 19.0129 4.80981 20.452 4.80981Z"
        fill={primary}
      />
      <path
        d="M32.6144 10.1432C32.6144 10.716 32.5947 11.1506 32.5454 11.4272H24.897C25.2321 12.5827 26.0403 13.1556 27.3117 13.1556C28.0608 13.1556 28.6128 12.8988 28.9479 12.3753H32.5159C32.2498 13.4815 31.6387 14.3704 30.6925 15.0222C29.7364 15.6741 28.6029 16 27.2723 16C25.5278 16 24.1085 15.4766 23.0144 14.4198C21.9401 13.3827 21.3979 12.0296 21.3979 10.3605C21.3979 8.72098 21.9203 7.36789 22.975 6.32098C24.0493 5.27406 25.4391 4.74072 27.1442 4.74072C28.7114 4.74072 30.0222 5.24443 31.067 6.25183C32.1019 7.24937 32.6144 8.5432 32.6144 10.1432ZM27.1245 7.51603C25.9319 7.51603 25.1828 8.11851 24.8871 9.31357H29.2534C29.0563 8.11851 28.3466 7.51603 27.1245 7.51603Z"
        fill={primary}
      />
      <path
        d="M41.1501 4.65185C42.5103 4.65185 43.6142 5.10617 44.452 6.00493C45.2898 6.89382 45.7136 8.0889 45.7136 9.60001V15.7531H42.1259V10.1827C42.1259 9.44198 41.9485 8.87903 41.6035 8.49384C41.2586 8.10866 40.7756 7.92099 40.1547 7.92099C39.4746 7.92099 38.8241 8.27656 38.2031 8.98767V15.763H34.5957V0H38.2031V5.70865C39.1198 4.99754 40.0955 4.65185 41.1501 4.65185Z"
        fill={primary}
      />
      <path
        d="M59.404 4.98773V15.7532H55.8163V15.1507C54.9588 15.6939 53.9732 15.9705 52.8692 15.9705C51.3908 15.9705 50.139 15.4273 49.1041 14.3507C48.0593 13.2544 47.5271 11.9211 47.5271 10.3507C47.5271 8.78032 48.0495 7.44699 49.1041 6.3507C50.139 5.27415 51.3908 4.73096 52.8692 4.73096C53.9732 4.73096 54.9588 5.00749 55.8163 5.5507V4.97787H59.404V4.98773ZM53.5986 13.0273C54.466 13.0273 55.2052 12.6914 55.8163 12.0297V8.67169C55.215 8.00996 54.4758 7.67415 53.5986 7.67415C52.8791 7.67415 52.268 7.93095 51.785 8.45441C51.3021 8.96799 51.0557 9.60996 51.0557 10.3606C51.0557 11.1112 51.3021 11.7433 51.785 12.2569C52.268 12.7705 52.8791 13.0273 53.5986 13.0273Z"
        fill={primary}
      />
      <path
        d="M68.4028 4.65186C69.763 4.65186 70.8669 5.10617 71.7047 6.00494C72.5425 6.89383 72.9663 8.08891 72.9663 9.60002V15.7531H69.3786V10.1827C69.3786 9.44199 69.2012 8.87904 68.8563 8.49385C68.5113 8.10866 68.0283 7.92099 67.4074 7.92099C66.7273 7.92099 66.0768 8.27657 65.4558 8.98768V15.763H61.8484V4.99754H65.4558V5.70865C66.3725 4.99754 67.3581 4.65186 68.4028 4.65186Z"
        fill={primary}
      />
      <path
        d="M86.5436 4.92352V15.689H82.9559V15.0865C82.0984 15.6297 81.1128 15.9062 80.0089 15.9062C78.5304 15.9062 77.2787 15.363 76.2438 14.2865C75.199 13.1902 74.6667 11.8569 74.6667 10.2865C74.6667 8.71611 75.1891 7.38279 76.2438 6.28649C77.2787 5.20994 78.5304 4.66675 80.0089 4.66675C81.1128 4.66675 82.0984 4.94328 82.9559 5.48649V4.91366H86.5436V4.92352ZM80.7382 12.963C81.6056 12.963 82.3448 12.6272 82.9559 11.9655V8.60748C82.3547 7.94576 81.6154 7.60994 80.7382 7.60994C80.0187 7.60994 79.4076 7.86674 78.9247 8.3902C78.4417 8.90378 78.1953 9.54575 78.1953 10.2964C78.1953 11.047 78.4417 11.6791 78.9247 12.1927C79.4076 12.7063 80.0089 12.963 80.7382 12.963Z"
        fill={primary}
      />
    </svg>
  );
};

export default CrehanaLogo;
