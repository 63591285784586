// DON'T EDIT THIS FILE!! IT'S AUTO-GENERATED USING GRAPHQL-CODE-GENERATOR CLI
/* eslint-disable camelcase */
import * as Types from '../../../../Types/__generated__/types';

import gql from 'graphql-tag.macro';
import * as ApolloReactCommon from 'react-apollo';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type AuthContextLoginUserMutationMutationVariables = Types.Exact<{
  username: Types.Scalars['String'];
  password: Types.Scalars['String'];
  nextUrl: Types.Scalars['String'];
  giftcardToken?: Types.Maybe<Types.Scalars['String']>;
  organizationSlug?: Types.Maybe<Types.Scalars['String']>;
  crehanaGiftCardToken?: Types.Maybe<Types.Scalars['String']>;
}>;

export type AuthContextLoginUserMutationMutation = {
  __typename?: 'PublicMutations';
  loginUser?: Types.Maybe<{
    __typename?: 'LoginUserPayload';
    isB2B?: Types.Maybe<boolean>;
    redirect?: Types.Maybe<string>;
    success?: Types.Maybe<boolean>;
    user?: Types.Maybe<{
      __typename?: 'UserNode';
      originalId?: Types.Maybe<number>;
      firstName?: Types.Maybe<string>;
      lastName?: Types.Maybe<string>;
      phoneCode?: Types.Maybe<string>;
      phoneNumber?: Types.Maybe<string>;
      isPremiumMember?: Types.Maybe<boolean>;
      hasOrganization: boolean;
    }>;
    messages?: Types.Maybe<
      Array<
        Types.Maybe<{
          __typename?: 'MessageNode';
          field?: Types.Maybe<string>;
          message?: Types.Maybe<string>;
        }>
      >
    >;
  }>;
};

export const AuthContextLoginUserMutationDocument = gql`
  mutation AuthContextLoginUserMutation(
    $username: String!
    $password: String!
    $nextUrl: String!
    $giftcardToken: String
    $organizationSlug: String
    $crehanaGiftCardToken: String
  ) {
    loginUser(
      input: {
        username: $username
        password: $password
        nextUrl: $nextUrl
        giftcardToken: $giftcardToken
        organizationSlug: $organizationSlug
        crehanaGiftCardToken: $crehanaGiftCardToken
      }
    ) {
      user {
        originalId
        firstName
        lastName
        phoneCode
        phoneNumber
        isPremiumMember
        hasOrganization
      }
      messages {
        field
        message
      }
      isB2B
      redirect
      success
    }
  }
`;

/**
 * __useAuthContextLoginUserMutationMutation__
 *
 * To run a mutation, you first call `useAuthContextLoginUserMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthContextLoginUserMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authContextLoginUserMutationMutation, { data, loading, error }] = useAuthContextLoginUserMutationMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      nextUrl: // value for 'nextUrl'
 *      giftcardToken: // value for 'giftcardToken'
 *      organizationSlug: // value for 'organizationSlug'
 *      crehanaGiftCardToken: // value for 'crehanaGiftCardToken'
 *   },
 * });
 */
export function useAuthContextLoginUserMutationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AuthContextLoginUserMutationMutation,
    AuthContextLoginUserMutationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AuthContextLoginUserMutationMutation,
    AuthContextLoginUserMutationMutationVariables
  >(AuthContextLoginUserMutationDocument, baseOptions);
}
export type AuthContextLoginUserMutationMutationHookResult = ReturnType<
  typeof useAuthContextLoginUserMutationMutation
>;
export type AuthContextLoginUserMutationMutationResult = ApolloReactCommon.MutationResult<
  AuthContextLoginUserMutationMutation
>;
export type AuthContextLoginUserMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AuthContextLoginUserMutationMutation,
  AuthContextLoginUserMutationMutationVariables
>;
