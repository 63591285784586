import React from 'react';

import Loader from 'crehana-ui/Loader';

const CurrencyLoder = ({ isDark }: { isDark?: boolean }) => {
  return (
    <div
      className={`rounded-5 h-56 flex items-center justify-center ${
        isDark ? 'bg-base-light-dark-mode' : 'bg-gray-light'
      }`}
      style={{ width: 260 }}
    >
      <Loader size={24} />
    </div>
  );
};

export default CurrencyLoder;
