/**
  # How to add more constants

  Please only add generic events.

  GOOD: "Buy", "Add to cart", "Show dialog", "Go to checkout"
  WRONG: "Add to cart from "

  You can make the event more specific adding properties. You can add as many properties as you want, but choose
  decriptive names.

  Commom properties:
    - origin: Reference component or page section where the event is triggered.
      eg.:  "footer", "main menu", "sidebar"
    - page: Current web page
      eg.: "Membership landing", "Catalog", "Checkout"

  Important note:
  If you find a non generic event here, please report it as soon as posible to fix it.
*/
export default {
  ENROLL: 'Enroll',
  // Wishlist events
  BUY_WISHLIST: 'Buy wishlist',
  ADD_TO_WISHLIST: 'Add to wishlist',
  REMOVE_FROM_WISHLIST: 'Remove from wishlist',
  // Shopping cart Events
  ADD_TO_CART: 'Add to cart',
  REMOVE_FROM_CART: 'Remove from cart',
  BUY_SHOPPING_CART: 'Buy shopping cart',
  // Checkout
  BUY: 'Buy product',
  // Checkout dialog
  SHOW_CHECKOUT_DIALOG: 'Show checkout dialog',
  SHOW_BOTTOMSHEET_PREVIEW: 'SHOW_BOTTOMSHEET_PREVIEW',
  SHOW_SHOPPINGPOPUP_BOTTOMSHEET: 'SHOW_SHOPPINGPOPUP_BOTTOMSHEET',
  // Black friday
  KEEP_BUYING: 'Keep buying',
  GO_TO_SHOPPING_CART_LANDING: 'Go to shopping cart landing',
  // Welcome flux
  WELCOME_FLUX: 'Welcome flux',
  // BundleDetail
  SEND_BUNDLE_AS_GIFT: 'SEND_BUNDLE_AS_GIFT',
  SEND_BUNDLE_AS_GIFT_SUCESS: 'SEND_BUNDLE_AS_GIFT_SUCESS',
  // GiftCard
  GIFT_CARD_MODAL_MOUNTED: 'Gift card dialog mounted',
  GIFT_CARD_SENT: 'Gift sent',
  /** *********************** */
  /** *********************** */
  /** Please use GENERIC, events like
   and just add props as you need */
  REGISTER: 'Register',
  LOGIN: 'Login',
  VIEW_PAGE: 'VIEW_PAGE',
  SEND_FORM: 'Send form',
  DISMISS: 'Dismiss',
  REGISTER_EXTRA_DATA: 'Register extra data',
  CLICK: 'Click',
  CLOSE_DIALOG: 'Close dialog',
  /** *********************** */
  /** *********************** */
  /** *********************** */
  // Welcome Flux
  WELCOME_FLUX_MORE_COURSES: 'Welcome flux check more courses',
  // User Profile
  GO_TO_COURSE_FROM_PROFILE: 'Go to course from profile',
  GO_TO_PROJECT_FROM_PROFILE: 'Go to project from profile',
  OPEN_CV: 'Open CV',
  CREHANA_LOVE_GIFT_MEMBERSHIP: 'Crehana Love - Gift membership',
  CREHANA_LOVE_SEND_GIFT_MEMBERSHIP_FORM:
    'Crehana Love - Send gift membership form',
  CREHANA_LOVE_CHANGE_EMAIL: 'Crehana Love - Change email',
  CLOSE_NOTIFICATION: 'Close notification',
  // Trome
  GO_TO_BUNDLES_LANDING: 'Go to bundles landing',

  // Onboarding
  ONBOARDING_OBJECTIVES: 'Submit objectives',
  ONBOARDING_INTERESTS: 'Submit interests',
  ONBOARDING_RECOMMENDATIONS_ADD_TO_CART: 'Add to cart from Onboarding',
  ONBOARDING_RECOMMENDATIONS_BUY_NOW: 'Buy Now from Onboarding',
  ONBOARDING_RECOMMENDATIONS_ENROLL_NOW: 'Enroll Now from Onboarding',

  // Onboarding V2
  ONBOARDING_FORM_USER: 'Form user data basic',
  ONBOARDING_RENDER_SUBCATEGORY: 'Render skills',
  ONBOARDING_SELECT_SUBCATEGORY: 'Select skills',
  ONBOARDING_RENDER_CATEGORY: 'Render interests',
  ONBOARDING_SELECT_CATEGORY: 'Select interests',
  ONBOARDING_RENDER_OCCUPATION: 'Render occupation',
  ONBOARDING_SELECT_OCCUPATION: 'Select occupation',

  // Onboarding V3
  ONBOARDING_FILL_OTHER_OCCUPATION_FIELD:
    'Fill other occupation field in Onboarding profile form',
  ONBOARDING_FILL_COMPANY_NAME_FIELD:
    'Fill company name field in Onboarding profile form',
  ONBOARDING_SUPPORT_CTA_CARD_CLICKED:
    'Click in CTA Card from Onboarding Support',

  // Course Preview Dialog
  GO_TO_COURSE_FROM_COURSE_PREVIEW: 'Go to course from course preview',
  GO_TO_MEMBERSHIP_LANDING: 'Go to memebership landing from course preview',
  ENROLLMENT_TO_COURSE_FROM_COURSE_PREVIEW:
    'Enrollment to course from course preview',
  BUY_NOW_FROM_COURSE_PREVIEW: 'Buy now from course preview',
  // membership
  CLICK_MEMBERSHIP_PRODUCT: 'Click in membership product',
  //
  GO_TO: 'Go to',
  SCROLL_TO: 'Scroll to',
  RENDER_COMPONENT: 'Render component',
  RENDER_PAGE: 'Render page',

  // User Data form
  USER_DATA_FORM_ONDOARDING: 'User data form shown in Onboarding',
  USER_DATA_FORM_ONDOARDING_SUBMIT: 'User data form submitted in Onboarding',

  // User Data form HomeUser
  USER_DATA_NOTIFICATION_HOMEUSER: 'User data notification shown in HomeUser',
  USER_DATA_FORM_HOMEUSER: 'User data form shown in HomeUser',
  USER_DATA_FORM_HOMEUSER_SUBMIT: 'User data form submitted in HomeUser',

  // Course Dashboard
  GO_TO_MENTOR_CHAT: 'Go to mentor chat from CourseDashboard',
  SHOW_DIALOG: 'Show dialog',

  // B2B Landing
  B2B_LANDING_PAGE_VIEW: 'B2B Landing page view',
  GO_TO_PRICING_PAGE: 'Go to pricing page',
  GO_TO_TRIAL_FORM: 'Go to trial form',
  SUBMIT_TRIAL_FORM: 'Submit trial form',

  DOWNLOAD_APPS: {
    APP_STORE: 'DOWNLOAD_APPS__APP_STORE',
    PLAY_STORE: 'DOWNLOAD_APPS__PLAY_STORE',
  },

  ENROLLMENT: 'Enrollment desde UI',
  CASHBACK: 'cashBackDialog',
};
