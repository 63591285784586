import React, { createContext, FC, useRef, useCallback } from 'react';

import { I18nTranslation, TConfig } from './types';
import { mergeTranslations, genMemoKey, getTranslationValue } from './utils';

interface CrehanaI18nContextValues {
  t(key: string, config?: TConfig): string;
  languageKey: string;
  debug: boolean;
  addResourceBundle: (bundle: I18nTranslation) => void;
}

export const CrehanaI18nContext = createContext<
  CrehanaI18nContextValues | undefined
>(undefined);

export interface I18nProviderProps {
  /** The same key used for translations */
  languageKey: string;
  /**
   * Translations coming from the server.
   *
   * This translations will have more priority than static translations
   * ie: if both share the same key, we'll overwrite the static one.
   */
  translations?: I18nTranslation;
  /** Used for local translation files */
  defaultTranslations?: I18nTranslation;
  /**
   * The default namespace, useful to avoid having to provide the namespace on
   * every component
   */
  defaultNs?: string;
  /**
   * Used for searching keys in other languaguage if it does not exist on
   * the current language.
   */
  fallbackLanguageKey?: string;
  /** Useful to search for translations in other namespaces */
  fallbackNs?: string[];
  /**
   * Useful to log relevant information (development only)
   * @default false
   */
  debug?: boolean;
}

interface LogDebugInfo {
  key: string;
  translation: string | null;
  config?: TConfig;
  memoized: boolean;
}

function logDebugInfo({ key, translation, config, memoized }: LogDebugInfo) {
  console.group('Crehana I18n');
  console.log('Key:', key);
  console.log('Value:', translation);
  console.log('Config:', config);
  console.log('Was memoized:', memoized);
  console.groupEnd();
}

export const I18nProvider: FC<I18nProviderProps> = ({
  languageKey,
  translations = {},
  defaultTranslations = {},
  defaultNs,
  fallbackLanguageKey,
  fallbackNs,
  debug = false,
  children,
}) => {
  const allTranslations = useRef(
    mergeTranslations(defaultTranslations, translations),
  );
  // memoized key format = language:namespace:key
  const memoized = useRef<{ [key: string]: string | null }>({});
  // Using ref because we don't expect this value to update at runtime
  const isDebugActive = useRef(debug && process.env.NODE_ENV === 'development');

  const t = useCallback(
    (key: string, config?: TConfig): string => {
      const actualConfig = {
        namespace: defaultNs,
        ...config,
        fallbackNs,
        fallbackLanguageKey,
      };

      const memoKey = genMemoKey(languageKey, key, actualConfig.namespace);
      const cachedT = memoized.current[memoKey];

      if (cachedT && !config?.interpolation) {
        if (isDebugActive.current) {
          logDebugInfo({
            key,
            translation: cachedT,
            config: actualConfig,
            memoized: true,
          });
        }
        return cachedT;
      }

      const translation = getTranslationValue(
        key,
        languageKey,
        allTranslations.current,
        actualConfig,
      );

      if (isDebugActive.current) {
        logDebugInfo({
          key,
          translation,
          config: actualConfig,
          memoized: false,
        });
      }

      // Don't memoize not found keys
      if (translation !== key) {
        memoized.current[memoKey] = translation;
      }
      return translation;
    },
    [languageKey, fallbackNs],
  );

  const addResourceBundle = (bundle: I18nTranslation) => {
    allTranslations.current = mergeTranslations(
      bundle,
      allTranslations.current,
    );
  };

  return (
    <CrehanaI18nContext.Provider
      value={{
        t,
        languageKey,
        debug: isDebugActive.current,
        addResourceBundle,
      }}
    >
      {children}
    </CrehanaI18nContext.Provider>
  );
};
