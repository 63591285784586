/**
 * Useful to apply extra classNames to an element avoiding duplicates
 * It compares prefixes to keep only the ones applied on the base classes string.
 *
 * @param {string} baseCls - The classNames that will prevail
 * @param {string} extraCls - Extra classNames, duplicates will be ignored
 *
 * @example
 * const baseCls = 'cls1 cls2 cls3 py-16';
 * const extraCls = 'cls3 cls4 py-24';
 * const finalCls = parseClassnames(baseCls, extraCls);
 *
 * console.log(finalCls); // cls1 cls2 cls3 cls4 py-16
 */
const parseClassnames = (baseCls: string, extraCls: string) => {
  const baseClasses = baseCls.split(' ');
  const extraClasses = extraCls.split(' ');
  let classNames = baseClasses.join(' ');

  extraClasses.forEach(extra => {
    const cls = extra.substr(0, 1) === '-' ? extra.substring(1) : extra;
    const prefix = cls.split('-')[0];
    const repeated = baseClasses.find(c => c.includes(`${prefix}-`));

    if (!repeated) {
      classNames = classNames.concat(classNames.length ? ' ' : '', extra);
    }
  });

  return classNames;
};

export default parseClassnames;
