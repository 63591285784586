import React, { FC, useMemo } from 'react';
import cn from 'classnames';
import { RowProps, ColProps, ColValue } from './types';
import getMarginClass from './helpers/getMarginClass';
import useDebug from '../useDebug';
import { DEFAULT_GUTTER, DASHBOARD_GUTTER } from './constants';

const Row: FC<RowProps> = ({
  children: childrenProp,
  type = 'default',
  gutter: gutterProp,
  itemsPerRow,
  nowrap,
  totalCols: totalColsProp,
  debug: debugProp,
  ignoreKeyboardDebug,
  className = '',
  ...rowProps
}) => {
  const debug = useDebug(debugProp, ignoreKeyboardDebug);

  const isDashboard = type === 'dashboard';
  const defaultGutter = isDashboard ? DASHBOARD_GUTTER : DEFAULT_GUTTER;
  const gutter = gutterProp || defaultGutter;
  const totalCols = totalColsProp || 12;

  const children = useMemo(
    () =>
      React.Children.map(childrenProp, child => {
        if (!React.isValidElement(child)) {
          return null;
        }
        let childProps: ColProps = { gutter, nowrap, totalCols };

        if (itemsPerRow) {
          if (
            typeof itemsPerRow === 'number' ||
            typeof itemsPerRow === 'string'
          ) {
            childProps = {
              ...childProps,
              xs:
                typeof itemsPerRow === 'number'
                  ? ((12 / itemsPerRow) as ColValue)
                  : itemsPerRow,
            };
          } else {
            const xs = itemsPerRow.xs || 1;
            const sm = itemsPerRow.sm || xs;
            const md = itemsPerRow.md || sm;
            const lg = itemsPerRow.lg || md;

            childProps = {
              ...childProps,
              xs: typeof xs === 'number' ? ((12 / xs) as ColValue) : xs,
            };

            if (sm !== xs) {
              childProps = {
                ...childProps,
                sm: typeof sm === 'number' ? ((12 / sm) as ColValue) : sm,
              };
            }
            if (md !== sm) {
              childProps = {
                ...childProps,
                md: typeof md === 'number' ? ((12 / md) as ColValue) : md,
              };
            }
            if (lg !== md) {
              childProps = {
                ...childProps,
                lg: typeof lg === 'number' ? ((12 / lg) as ColValue) : lg,
              };
            }
          }
        }

        return React.cloneElement(child, { ...childProps, ...child.props });
      }),
    [childrenProp, type, gutter, itemsPerRow, nowrap, totalCols],
  );

  const gutterClasses = useMemo(() => {
    if (typeof gutter === 'number') {
      return getMarginClass(gutter, className, nowrap);
    }
    const xs = typeof gutter.xs === 'number' ? gutter.xs : 8;
    const sm = typeof gutter.sm === 'number' ? gutter.sm : xs;
    const md = typeof gutter.md === 'number' ? gutter.md : sm;
    const lg = typeof gutter.lg === 'number' ? gutter.lg : md;
    let classes = getMarginClass(xs, className, nowrap);

    if (sm !== xs) {
      classes = classes.concat(
        ` ${getMarginClass(sm, className, nowrap, 'sm')}`,
      );
    }
    if (md !== sm) {
      classes = classes.concat(
        ` ${getMarginClass(md, className, nowrap, 'md')}`,
      );
    }
    if (lg !== md) {
      classes = classes.concat(
        ` ${getMarginClass(lg, className, nowrap, 'lg')}`,
      );
    }

    return classes;
  }, [gutter]);

  return (
    <div
      className={cn(
        'flex',
        {
          'flex-wrap': !nowrap,
          'border border-solid border-secondary-light': debug,
        },
        gutterClasses,
        className,
      )}
      {...rowProps}
    >
      {children}
    </div>
  );
};

export default Row;
