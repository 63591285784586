import React, { FC } from 'react';

import { useTranslation } from 'crehana-i18n';
import { Col } from 'crehana-ui/GridV2';
import useCollapse from 'crehana-ui/hooks/useCollapse';
import ArrowBottom from 'crehana-icons/ArrowBottom';

import mixpanel from 'Jsx/Utils/mixpanelTracking';

import { FooterLink } from '../types';
import { MAIN_FOOTER_PAGE_KEY } from '../constants';
import { GO_TO_HELP_CENTER } from '../events';

interface LinksColumnProps {
  isDark?: boolean;
  isDesktop?: boolean;
  title: string;
  links: FooterLink[];
}

const isExternalLink = (link: string) => {
  return link.includes('http://') || link.includes('https://');
};

const LinksColumn: FC<LinksColumnProps> = ({
  title,
  links,
  isDark,
  isDesktop,
}) => {
  const { t } = useTranslation(MAIN_FOOTER_PAGE_KEY);
  const { getCollapseProps, getToggleProps, isOpen } = useCollapse({
    defaultOpen: false,
  });

  const trackHelpCenterLink = () => {
    mixpanel.track(GO_TO_HELP_CENTER, { location: 'footer' });
  };

  const collapseProps = !isDesktop ? getCollapseProps<HTMLUListElement>() : {};

  return (
    <Col
      xs={10}
      sm={4}
      md={3}
      offset={{
        xs: 1,
        sm: 0,
      }}
      nowrap
      className={`sm:flex flex-col items-center md:block ${
        isDark ? 'text-base-lighter' : 'text-base-dark'
      }`}
    >
      <div>
        {!isDesktop ? (
          <button
            type="button"
            className="font-button mb-20 w-full"
            {...getToggleProps()}
          >
            <span className="flex justify-between">
              {t(title)}

              <ArrowBottom
                size={16}
                ignoreColor
                className={`fill-current text-base-lighter ${
                  isOpen ? ' open' : ''
                }`}
                css={`
                  transition: transform 350ms ease-in-out;
                  &.open {
                    transform: rotate(180deg);
                  }
                `}
              />
            </span>
          </button>
        ) : (
          <span className="font-button mb-20 block">{t(title)}</span>
        )}

        <ul className={isOpen ? 'mb-16' : undefined} {...collapseProps}>
          {links.map(l => (
            <li key={l.label} className="pb-16">
              <a
                href={l.href}
                className="font-subtitle4 text-inherit hover:text-secondary-light transition-all"
                onClick={
                  l.label === 'COMPANY_HELP_CENTER'
                    ? trackHelpCenterLink
                    : undefined
                }
                {...(isExternalLink(l.href)
                  ? { target: '_blank', rel: 'noopener noreferrer' }
                  : {})}
              >
                {t(l.label)}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </Col>
  );
};

export default LinksColumn;
