import { css, Interpolation, ThemeProps } from 'styled-components';

import { SpacingType, ResponsiveSpacingType, CuiTheme } from '../styles/types';

const getSizeCss = (size: SpacingType | ResponsiveSpacingType) => {
  if (typeof size === 'number') {
    return css`
      width: ${size}px;
      height: ${size}px;
    `;
  }
  let sizeCss: Interpolation<ThemeProps<CuiTheme>> = css`
    width: ${size.xs}px;
    height: ${size.xs}px;
  `;
  let prevSize = size.xs;

  if (size.sm && size.sm !== prevSize) {
    sizeCss = css`
      ${sizeCss}
      ${({ theme }) => theme.breakpoints.up('sm')} {
        width: ${size.sm}px;
        height: ${size.sm}px;
      }
    `;
    prevSize = size.sm;
  }
  if (size.md && size.md !== prevSize) {
    sizeCss = css`
      ${sizeCss}
      ${({ theme }) => theme.breakpoints.up('md')} {
        width: ${size.md}px;
        height: ${size.md}px;
      }
    `;
    prevSize = size.md;
  }
  if (size.lg && size.lg !== prevSize) {
    sizeCss = css`
      ${sizeCss}
      ${({ theme }) => theme.breakpoints.up('lg')} {
        width: ${size.lg}px;
        height: ${size.lg}px;
      }
    `;
  }
  return sizeCss;
};

export default getSizeCss;
