import React from 'react';
import { IconProps } from './types';

const Facebook: React.FC<IconProps> = ({
  className,
  color = '#181B32',
  size = 32,
  ignoreColor,
}) => (
  <svg
    className={className}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={0.4375 * size}
    height={size}
    viewBox="0 0 14 32"
  >
    <path
      fill={!ignoreColor ? color : undefined}
      d="M14.418 10.361h-4.91v-3.321c0-1.248 0.801-1.539 1.365-1.539h3.465v-5.483l-4.773-0.019c-5.298 0-6.503 4.089-6.503 6.708v3.655h-3.062v5.65h3.063v15.988h6.445v-15.988h4.347z"
    />
  </svg>
);

export default Facebook;
