import React, { FC, useMemo } from 'react';
import cn from 'classnames';
import { ContainerProps } from './types';
import useDebug from '../useDebug';
import useDebugGrid from '../useDebugGrid';
import { DEFAULT_GUTTER, DASHBOARD_GUTTER } from './constants';
import Row from './Row';
import Col from './Col';

const Container: FC<ContainerProps> = ({
  children: childrenProp,
  type = 'default',
  full,
  gutter: gutterProp,
  debug: debugProp,
  debugGrid: debugGridProp,
  ignoreKeyboardDebug,
  className = '',
  ...containerProps
}) => {
  const debug = useDebug(debugProp, ignoreKeyboardDebug);
  const debugGrid = useDebugGrid(debugGridProp, ignoreKeyboardDebug);

  const isDashboard = type === 'dashboard';
  const defaultGutter = isDashboard ? DASHBOARD_GUTTER : DEFAULT_GUTTER;
  const gutter = gutterProp || defaultGutter;

  const children = useMemo(
    () =>
      React.Children.map(childrenProp, child => {
        if (!React.isValidElement(child)) {
          return null;
        }
        return React.cloneElement(child, { gutter, ...child.props });
      }),
    [childrenProp, type, gutter],
  );

  const containerClassname = useMemo(() => {
    if (!full) {
      return isDashboard ? 'cui-dashboard-container' : 'cui-container';
    }
    if (typeof full === 'boolean') {
      return 'w-full';
    }
    const xs = full.xs || false;
    const sm = typeof full.sm === 'boolean' ? full.sm : xs;
    const md = typeof full.md === 'boolean' ? full.md : sm;
    const lg = typeof full.lg === 'boolean' ? full.lg : md;
    let classes = xs ? 'w-full' : 'xs:cui-container';

    classes = sm
      ? classes.concat(!xs ? ' sm:w-full' : '')
      : classes.concat(
          isDashboard ? ' sm:cui-dashboard-container' : ' sm:cui-container',
        );
    classes = md
      ? classes.concat(!sm ? ' md:w-full' : '')
      : classes.concat(
          isDashboard ? ' md:cui-dashboard-container' : ' md:cui-container',
        );
    classes = lg
      ? classes.concat(!md ? ' lg:w-full' : '')
      : classes.concat(
          isDashboard ? ' lg:cui-dashboard-container' : ' lg:cui-container',
        );

    return classes;
  }, [full]);

  return (
    <div
      className={cn(
        containerClassname,
        {
          'border border-solid border-gray-dark': debug,
          relative: debugGrid,
        },
        className,
      )}
      {...containerProps}
    >
      {children}
      {debugGrid && (
        <Row className="absolute inset-0" gutter={gutter}>
          {Array.from(Array(12)).map((_, index) => (
            <Col xs={1} key={`grid-col-${index + 1}`}>
              <div className="h-full bg-base-lighter-16" />
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

export default Container;
