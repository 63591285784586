import React, { FC, Fragment } from 'react';
import cn from 'classnames';

import { ResponsiveTextProps } from './types';

const ResponsiveText: FC<ResponsiveTextProps> = ({ text, className }) => {
  if (typeof text === 'string') {
    if (className) {
      return <span className={className}>{text}</span>;
    }
    return <Fragment>{text}</Fragment>;
  }
  return (
    <Fragment>
      <span
        className={cn(className, {
          'sm:hidden': !!text.sm,
          'md:hidden': !text.sm && !!text.md,
          'lg:hidden': !(text.sm || text.md) && !!text.lg,
        })}
      >
        {text.xs}
      </span>
      {text.sm && (
        <span
          className={cn(className, 'hidden sm:inline', {
            'md:hidden': !!text.md,
            'lg:hidden': !text.md && !!text.lg,
          })}
        >
          {text.sm}
        </span>
      )}
      {text.md && (
        <span
          className={cn(className, 'hidden md:inline', {
            'lg:hidden': !!text.lg,
          })}
        >
          {text.md}
        </span>
      )}
      {text.lg && (
        <span className={cn(className, 'hidden lg:inline')}>{text.lg}</span>
      )}
    </Fragment>
  );
};

export default ResponsiveText;
