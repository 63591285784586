import React, { FC } from 'react';

import { Row, Col } from 'crehana-ui/GridV2';
import Facebook from 'crehana-icons/Facebook';
import Twitter from 'crehana-icons/Twitter';
import Instagram from 'crehana-icons/Instagram';
import Linkedin from 'crehana-icons/Linkedin';
import YouTube from 'crehana-icons/Youtube';

import Lazyload from 'Jsx/Shared/Lazyload';
import Loadable from 'Jsx/Shared/Loadable';

import AppsColumn from 'Jsx/MainFooter/components/AppsColumn';

import Copyright from './Copyright';
import SocialLink from './SocialLink';
import CurrencyLoader from '../../components/CurrencyLoader';

interface BottomSectionProps {
  isDark?: boolean;
}

const BottomSection: FC<BottomSectionProps> = ({ isDark }) => {
  return (
    <Row className="pb-32 sm:pb-48 lg:pb-64 flex items-center md:items-end lg:items-center">
      <Copyright isDark={isDark} className="hidden md:block" />

      <AppsColumn
        isDark={isDark}
        className="md:hidden mb-24 sm:mb-0 flex flex-col items-center sm:items-start"
      />

      <Col
        sm={5}
        md={4}
        lg={6}
        offset={{ sm: 1, md: 0 }}
        nowrap
        className="flex self-end justify-end items-center sm:items-start md:items-end lg:items-center flex-col lg:flex-row"
      >
        <span
          className={`md:mr-12 mb-24 sm:mb-32 md:mb-24 lg:mb-0 ${
            isDark ? 'text-white' : 'text-gray-dark'
          }`}
        >
          <SocialLink
            link="https://www.facebook.com/Crehanacom/"
            icon={Facebook}
          />
          <SocialLink link="https://twitter.com/crehanacom" icon={Twitter} />
          <SocialLink
            link="https://www.instagram.com/crehanacom/"
            icon={Instagram}
          />
          <SocialLink
            link="https://www.linkedin.com/school/crehana/"
            icon={Linkedin}
          />
          <SocialLink
            link="https://www.youtube.com/channel/UCg6_yPdtalblZ0Peqjv5rPg/featured"
            icon={YouTube}
          />
        </span>

        <Lazyload>
          <Loadable
            module={() =>
              import(
                /* webpackChunkName: "main-footer-currency-dropdown"  */ 'Jsx/MainFooter/components/CurrencyDropdownSelector'
              )
            }
            Loader={<CurrencyLoader isDark={isDark} />}
            isDark={isDark}
          />
        </Lazyload>
      </Col>

      <Copyright isDark={isDark} className="md:hidden" />
    </Row>
  );
};

export default BottomSection;
