import { useState, Dispatch, SetStateAction } from 'react';

interface UseOpenState {
  isOpen?: boolean;
  defaultOpen?: boolean;
}

function useOpenState({
  isOpen,
  defaultOpen,
}: UseOpenState): [boolean, Dispatch<SetStateAction<boolean>>] {
  const [open, setOpen] = useState(defaultOpen || false);
  const definedOpen = typeof isOpen !== 'undefined' ? isOpen : open;

  return [definedOpen, setOpen];
}
export default useOpenState;
