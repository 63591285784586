import React, { FC, useEffect, useState } from 'react';

import { Row } from 'crehana-ui/GridV2';

import { CountryPrefix } from 'Jsx/global';
import matchMediaQuery from 'Jsx/Utils/matchMediaQuery';
import useMe from 'Jsx/Shared/Hooks/useMe';

import LinksColumn from '../components/LinksColumn';
import AppsColumn from '../components/AppsColumn';

import { COMPANY_LINKS, COMMUNITY_LINKS, HELP_LINKS } from '../constants';

interface TopSectionProps {
  countryPrefix: CountryPrefix;
  isDark?: boolean;
}

const TopSection: FC<TopSectionProps> = ({ countryPrefix, isDark }) => {
  const [isDesktop, setIsDesktop] = useState(true);
  const { me } = useMe();

  const linksCommunityFilters = me?.isPremiumMember
    ? COMMUNITY_LINKS.filter(links => links.label !== 'COMMUTITY_PREMIUM')
    : COMMUNITY_LINKS;

  useEffect(() => {
    setIsDesktop(matchMediaQuery('sm').matches);
  }, []);

  return (
    <Row className="pt-32 sm:pt-48 lg:pt-64 mb-32 md:mb-16 lg:mb-64">
      <LinksColumn
        isDark={isDark}
        isDesktop={isDesktop}
        title="COMPANY_TITLE"
        links={COMPANY_LINKS}
      />
      <LinksColumn
        isDark={isDark}
        isDesktop={isDesktop}
        title="COMMUNITY_TITLE"
        links={linksCommunityFilters}
      />
      <LinksColumn
        isDark={isDark}
        isDesktop={isDesktop}
        title="HELP_TITLE"
        links={HELP_LINKS(countryPrefix)}
      />

      <AppsColumn isDark={isDark} className="hidden md:block" />
    </Row>
  );
};

export default TopSection;
