import EVENTS from 'Jsx/Utils/tracking/events';
import customLocalStorage from 'Jsx/Utils/customLocalStorage';

enum TYPES {
  MIXPANEL = 'MIXPANEL',
}

const logMixpanel = (event, data) => {
  const TRACKS_VISIBILITY = customLocalStorage.getItem('TRACKS_VISIBILITY');

  const printMixpanelTrack = eval('console.log.bind(console)');

  if (TRACKS_VISIBILITY) {
    printMixpanelTrack({ 'Mixpanel Event': event, data });
  }
};

const mixpanel = {
  track(event: string, properties: { [key: string]: any } = {}, callback?) {
    try {
      if (window.mixpanel && window.mixpanel.track) {
        if (callback) {
          window.mixpanel.track(event, properties, callback);
          logMixpanel(event, properties);
        } else {
          window.mixpanel.track(event, properties);
          logMixpanel(event, properties);
        }
      } else if (callback) {
        callback();
      }
    } catch (error) {
      if (callback) {
        callback();
      }
      console.log('Error to send track to mixpanel', error);
    }
  },
  TYPES,
  EVENTS,
  PAGES: {
    SHOPPING_CART: 'Shopping cart',
    CAMPAIGN_LANDING: 'Campaign landing',
  },
};

export default mixpanel;
