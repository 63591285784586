/**
 * This function controll the error below
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Malformed_URI
 */

function customDecodeURIComponent(uri) {
  let decodedUri = '';

  try {
    decodedUri = decodeURIComponent(uri);
  } catch (error) {
    console.error(
      `Error to decode the uri: ${uri}. Check https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Malformed_URI for more info. `,
      error,
    );
  }

  return decodedUri;
}

export default customDecodeURIComponent;
