import React, { FC } from 'react';

import { useTranslation } from 'crehana-i18n';
import { Col } from 'crehana-ui/GridV2';
import PrimaryButton from 'crehana-ui/Buttons/PrimaryButton';
import GooglePlay from 'crehana-icons/GooglePlayIcon';
import Apple from 'crehana-icons/Apple';

interface AppsColumnProps {
  isDark?: boolean;
  className: string;
}

const AppsColumn: FC<AppsColumnProps> = ({ isDark, className }) => {
  const { t } = useTranslation('main-footer');

  return (
    <Col sm={4} md={3} offset={{ sm: 1, md: 0 }} nowrap className={className}>
      <p
        className={`font-button mb-20 ${
          isDark ? 'text-base-lighter' : 'text-base-dark'
        }`}
      >
        {t('FOOTER_DOWNLOAD')}
      </p>

      <div
        css={`
          ${props => props.theme.breakpoints.up('sm')} {
            width: 172px;
          }
        `}
      >
        <PrimaryButton
          label="Google play"
          href="https://play.google.com/store/apps/details?id=com.crehana.android"
          leftIcon={{
            component: GooglePlay,
            size: 18,
          }}
          className={`w-full mb-8 ${
            isDark ? 'bg-base-lighter-dark-mode' : 'bg-base-dark'
          }`}
        />
        <PrimaryButton
          label="App Store"
          href="https://itunes.apple.com/us/app/crehana/id1353031284"
          leftIcon={{
            component: Apple,
            size: 18,
          }}
          className={`w-full ${
            isDark ? 'bg-base-lighter-dark-mode' : 'bg-base-dark'
          }`}
        />
      </div>
    </Col>
  );
};

export default AppsColumn;
