export default {
  es: {
    COMPANY_TITLE: 'Compañía',
    COMPANY_HELP_CENTER: 'Centro de ayuda',
    COMPANY_ABOUT: 'Sobre Crehana',
    COMPANY_HIRING: '¡Estamos contratando!',
    COMPANY_CREHANA_FOR_BUSINESS: 'Crehana para empresas',

    COMMUNITY_TITLE: 'Comunidad',
    COMMUNITY_SCHOLARSHIPS: 'Becas',
    COMMUNITY_STORIES: 'Historias de éxito',
    COMMUNITY_PROJECTS: 'Galería de proyectos',
    COMMUNITY_RESOURCES: 'Recursos',
    COMMUNITY_BLOG: 'Blog',
    COMMUTITY_PREMIUM: 'Vuélvete Premium',

    HELP_TITLE: 'Enlaces de ayuda',
    HELP_PRIVACY: 'Políticas de privacidad',
    HELP_COOKIES: 'Política de cookies',
    HELP_ARCO: 'Solicitud ARCO',
    HELP_CANCELLATION: 'Política de cancelación',
    HELP_TERMS: 'Términos y condiciones',
    HELP_CLAIMS_BOOK: 'Libro de reclamaciones',

    APPS_TITLE: 'Descarga nuestra app',

    RIGHTS_RESERVED: 'Todos los derechos reservados',
  },
};
