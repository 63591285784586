import { CountryPrefix } from 'Jsx/global';
import { FooterLink } from './types';

export const MAIN_FOOTER_PAGE_KEY = 'main-footer';

export const COMPANY_LINKS: FooterLink[] = [
  {
    label: 'COMPANY_ABOUT',
    href: '/sobre-nosotros/',
  },
  {
    label: 'COMPANY_HIRING',
    href: '/trabaja-con-nosotros/',
  },
  {
    label: 'COMPANY_CREHANA_FOR_BUSINESS',
    href: '/empresas/',
  },
];

export const COMMUNITY_LINKS: FooterLink[] = [
  {
    label: 'COMMUNITY_SCHOLARSHIPS',
    href: '/becas/',
  },
  {
    label: 'COMMUNITY_STORIES',
    href: '/historias/',
  },
  {
    label: 'COMMUNITY_PROJECTS',
    href: '/proyectos/',
  },
  {
    label: 'COMMUNITY_RESOURCES',
    href: '/recursos/herramientas/',
  },
  {
    label: 'COMMUNITY_BLOG',
    href: '/blog/',
  },
  {
    label: 'COMMUTITY_PREMIUM',
    href: '/premium/',
  },
];

export const HELP_LINKS: (
  countryPrefix: CountryPrefix,
) => FooterLink[] = countryPrefix => {
  const links = [
    {
      label: 'COMPANY_HELP_CENTER',
      href: 'https://ayuda.crehana.com',
    },
    countryPrefix === 'mx'
      ? {
          label: 'Avisos de privacidad',
          href: '/legal/avisos-de-privacidad/mx/',
        }
      : {
          label: 'HELP_PRIVACY',
          href: '/legal/politicas-de-privacidad/',
        },
    {
      label: 'HELP_COOKIES',
      href: '/legal/politica-de-cookies/',
    },
    {
      label: 'HELP_ARCO',
      href: '/legal/solicitud-arco/',
    },
    {
      label: 'HELP_TERMS',
      href: '/legal/terminos-y-condiciones/',
    },
  ];

  if (countryPrefix === 'mx') {
    links.push({
      label: 'HELP_CANCELLATION',
      href: '/politica-cancelacion-mexico/',
    });
  }

  if (countryPrefix === 'pe') {
    links.push({
      label: 'HELP_CLAIMS_BOOK',
      href: '/libro-reclamaciones/',
    });
  }

  return links;
};
