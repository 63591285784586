import { IconProps } from '@crehana/crehana-icons/lib/types';
import { ResponsiveString } from '../ResponsiveText/types';

export type IconItemProp = {
  component: React.ComponentType<IconProps>;
  size?: number;
  hasFill?: boolean;
  hasStroke?: boolean;
  color?: string;
};

export type IconItemType = {
  component?: React.ComponentType<IconProps>;
  size: number;
  hasFill: boolean;
  hasStroke: boolean;
};

export type ButtonSizeType = 'xs' | 's' | 'm' | 'l';

export interface BaseButtonProps extends React.HTMLAttributes<any> {
  /**
   * Dynamic HTML tag to work as wrapper. If href is provided, default is 'a', otherwise is 'button'
   */
  as?: keyof JSX.IntrinsicElements;
  /**
   * Set disabled button property.
   */
  disabled?: boolean;
  /**
   * Useful to extend classnames.
   */
  className?: string;
  href?: string;
}

export type ButtonLabelType = string | ResponsiveString;

export interface ButtonWLabelProps extends BaseButtonProps {
  /**
   * Button label.
   * You can provide a single string or a different string for each breakpoint.
   * If not provided, children will be rendered.
   */
  label?: ButtonLabelType;
  /**
   * l: h-52 sm:h-56 px-48
   * m: h-48 px-40
   * s: h-40 px-32
   * xs: h-40 px-16
   *
   * @default "m"
   */
  size?: ButtonSizeType;
  /**
   * If true, the button will have full width.
   */
  full?: boolean;
  /**
   * Object containing properties for optional left icon.
   * Some icons are formed with stroke, some with fill, and others with both.
   * Check how is your icon built to enable or disable hasFill and hasStroke properties.
   *
   * @default { component: null, size: 24, hasFill: true, hasStroke: false }
   */
  leftIcon?: IconItemProp;
  /**
   * Object containing properties for optional right icon.
   * Some icons are formed with stroke, some with fill, and others with both.
   * Check how is your icon built to enable or disable hasFill and hasStroke properties.
   *
   * @default { component: null, size: 24, hasFill: true, hasStroke: false }
   */
  rightIcon?: IconItemProp;
  /**
   * Classname for the span (label) element
   */
  labelClassName?: string;
  [key: string]: any;
}

export const getDefaultIconValues = (
  iconProp?: IconItemProp,
  defaultSize?: number,
) => ({
  component: iconProp ? iconProp.component : undefined,
  size:
    iconProp && typeof iconProp.size === 'number'
      ? iconProp.size
      : defaultSize || 24,
  hasFill:
    iconProp && typeof iconProp.hasFill === 'boolean' ? iconProp.hasFill : true,
  hasStroke:
    iconProp && typeof iconProp.hasStroke === 'boolean'
      ? iconProp.hasStroke
      : false,
});
