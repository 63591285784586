import React, { FunctionComponent, useMemo } from 'react';
import styled from 'styled-components';
import cn from 'classnames';

import getSizeCss from '../helpers/getSizeCss';

import BASE_64 from './Base64';
import { LoaderProps } from './types';

type StyledLoaderProps = {
  color: string;
};

const StyledLoader = styled.div<StyledLoaderProps>`
  background-size: contain;
  background-position: center;
  background-image: url(${({ color }) => BASE_64[color]});
`;

const Loader: FunctionComponent<LoaderProps> = ({
  white,
  size = 48,
  className = '',
  css,
  ...loaderProps
}) => {
  const sizeCss = useMemo(() => getSizeCss(size), [size]);

  return (
    <StyledLoader
      className={cn('flex-shrink-0', className)}
      css={`
        ${sizeCss}
        ${css}
      `}
      color={white ? 'white' : 'default'}
      {...loaderProps}
    />
  );
};

export default Loader;
