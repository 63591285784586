import React, { FC, useMemo } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { ColProps } from './types';
import getPaddingClass from './helpers/getPaddingClass';
import getResponsiveStyled from '../helpers/getResponsiveStyledDefault';
import useDebug from '../useDebug';
import { DEFAULT_GUTTER, DASHBOARD_GUTTER } from './constants';

const StyledCol = styled.div<ColProps>`
  ${props => getResponsiveStyled(props)}
`;

const Col: FC<ColProps> = ({
  children,
  type = 'default',
  xs = 12,
  sm: smProp,
  md: mdProp,
  lg: lgProp,
  offset,
  gutter: gutterProp,
  nowrap,
  totalCols = 12,
  debug: debugProp,
  ignoreKeyboardDebug,
  className = '',
  ...colProps
}) => {
  const debug = useDebug(debugProp, ignoreKeyboardDebug);

  const isDashboard = type === 'dashboard';
  const defaultGutter = isDashboard ? DASHBOARD_GUTTER : DEFAULT_GUTTER;
  const gutter = gutterProp || defaultGutter;

  const gutterClasses = useMemo(() => {
    if (!gutter) {
      return '';
    }
    if (typeof gutter === 'number') {
      return getPaddingClass(gutter, className, nowrap);
    }
    const mobile = typeof gutter.xs === 'number' ? gutter.xs : 8;
    const sm = typeof gutter.sm === 'number' ? gutter.sm : mobile;
    const md = typeof gutter.md === 'number' ? gutter.md : sm;
    const lg = typeof gutter.lg === 'number' ? gutter.lg : md;
    let classes = getPaddingClass(mobile, className, nowrap);

    if (sm !== xs) {
      classes = classes.concat(
        ` ${getPaddingClass(sm, className, nowrap, 'sm')}`,
      );
    }
    if (md !== sm) {
      classes = classes.concat(
        ` ${getPaddingClass(md, className, nowrap, 'md')}`,
      );
    }
    if (lg !== md) {
      classes = classes.concat(
        ` ${getPaddingClass(lg, className, nowrap, 'lg')}`,
      );
    }

    return classes;
  }, [gutter]);

  return (
    <StyledCol
      className={cn(
        gutterClasses,
        { 'border border-solid border-purple-light': debug },
        className,
      )}
      totalCols={totalCols}
      xs={xs}
      sm={smProp}
      md={mdProp}
      lg={lgProp}
      offset={offset}
      {...colProps}
    >
      {children}
    </StyledCol>
  );
};

export default Col;
