/**
 * values[0] : Cookie name and value concatenated`
 * values[1] : Cookie name
 * values[2] : Cookie value
 */
import customDecodeURIComponent from './customDecodeURIComponent';

export const getCookie = name => {
  const result: { [key: string]: string } = {};

  document.cookie.split(';').forEach(cookie => {
    const values = cookie.trim().match(/(\w+)=(.*)/);

    if (values && name === values[1]) {
      result[values[1]] = values[2];
    }
  });

  return result;
};

/**
 * values[0] : Cookie name and value concatenated
 * values[1] : Cookie name
 * values[2] : Cookie value
 */
export const getAll = () => {
  const cookies = {};

  try {
    document.cookie.split(';').forEach(cookie => {
      const values = cookie.trim().match(/(\w+)=(.*)/);

      if (values) {
        cookies[values[1].trim()] = customDecodeURIComponent(values[2]);
      }
    });
  } catch (error) {
    console.error('getAll: Error to parse cookies', error);
  }

  return cookies;
};

/**
 * expDate : Must be an UTC date.
 */
export const setCookie = (
  name: string,
  value: string,
  expDays = 1,
  expDate = '',
) => {
  if (!name || !value) {
    console.warn(
      'You must pass the first two parameters (name and value) of the cookie',
    );
    return;
  }

  const expDateWithDays = new Date();
  const offSet = expDays * 24 * 60 * 60 * 1000;

  expDateWithDays.setTime(expDateWithDays.getTime() + offSet);

  document.cookie = `${name}=${value};expires=${expDate ||
    expDateWithDays.toUTCString()};path=/`;
};

export const deleteCookie = (name: string) => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
