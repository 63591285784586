import { CuiTheme, BreakpointKeyType } from '../../styles/types';

const parseTotalCols = (
  totalCols: number | Partial<Record<'xs' | BreakpointKeyType, number>>,
): {
  xs: number;
  sm: number;
  md: number;
  lg: number;
} => {
  let total = { xs: 12, sm: 12, md: 12, lg: 12 };

  if (typeof totalCols === 'number') {
    total = { xs: totalCols, sm: totalCols, md: totalCols, lg: totalCols };
  } else {
    const xs = totalCols?.xs || 12;
    const sm = totalCols?.sm || xs;
    const md = totalCols?.md || sm;
    const lg = totalCols?.lg || md;

    total = { xs, sm, md, lg };
  }
  return total;
};

const getWidthStyles = (total: number, span: number | 'auto') => {
  if (span === 'auto') {
    return 'flex: 1';
  }
  return `
    width: ${span >= total ? 100 : (span / total) * 100}%;
    max-width: ${span >= total ? 100 : (span / total) * 100}%;
  `;
};

const getResponsiveWidthStyles = (
  totalCols: {
    xs: number;
    sm: number;
    md: number;
    lg: number;
  },
  cols: {
    xs?: number | 'auto';
    sm?: number | 'auto';
    md?: number | 'auto';
    lg?: number | 'auto';
  },
): {
  xsWidth: string;
  smWidth: string;
  mdWidth: string;
  lgWidth: string;
} => {
  const { xs: xsProp, sm: smProp, md: mdProp, lg: lgProp } = cols;

  const xs = xsProp || totalCols.xs;
  const sm = smProp || xs;
  const md = mdProp || sm;
  const lg = lgProp || md;

  return {
    xsWidth: getWidthStyles(totalCols.xs, xs),
    smWidth:
      sm !== xs || totalCols.sm !== totalCols.xs
        ? getWidthStyles(totalCols.sm, sm)
        : '',
    mdWidth:
      md !== sm || totalCols.md !== totalCols.sm
        ? getWidthStyles(totalCols.md, md)
        : '',
    lgWidth:
      lg !== md || totalCols.lg !== totalCols.md
        ? getWidthStyles(totalCols.lg, lg)
        : '',
  };
};

const getOffsetStyles = (total: number, offset: number | 'auto') => {
  if (offset === 'auto') {
    return 'margin-left: auto;';
  }
  return `margin-left: ${offset >= total ? 100 : (offset / total) * 100}%;`;
};

const parseOffsets = (
  offset?:
    | number
    | 'auto'
    | Partial<Record<'xs' | BreakpointKeyType, number | 'auto'>>,
): {
  xs: number | 'auto';
  sm: number | 'auto';
  md: number | 'auto';
  lg: number | 'auto';
} => {
  if (!offset) {
    return { xs: 0, sm: 0, md: 0, lg: 0 };
  }
  if (typeof offset === 'number' || typeof offset === 'string') {
    return { xs: offset, sm: offset, md: offset, lg: offset };
  }
  const xs = offset.xs || 0;
  const sm = typeof offset.sm === 'undefined' ? xs : offset.sm;
  const md = typeof offset.md === 'undefined' ? sm : offset.md;
  const lg = typeof offset.lg === 'undefined' ? md : offset.lg;

  return { xs, sm, md, lg };
};

const getResponsiveOffsetStyles = (
  totalCols: {
    xs: number;
    sm: number;
    md: number;
    lg: number;
  },
  offset?:
    | number
    | 'auto'
    | Partial<Record<'xs' | BreakpointKeyType, number | 'auto'>>,
): {
  xsOff: string;
  smOff: string;
  mdOff: string;
  lgOff: string;
} => {
  const { xs, sm, md, lg } = parseOffsets(offset);

  return {
    xsOff: xs ? getOffsetStyles(totalCols.xs, xs) : '',
    smOff:
      sm !== xs || totalCols.sm !== totalCols.xs
        ? getOffsetStyles(totalCols.sm, sm)
        : '',
    mdOff:
      md !== sm || totalCols.md !== totalCols.sm
        ? getOffsetStyles(totalCols.md, md)
        : '',
    lgOff:
      lg !== md || totalCols.lg !== totalCols.md
        ? getOffsetStyles(totalCols.lg, lg)
        : '',
  };
};

const getResponsiveStyled = ({
  theme,
  xs,
  sm,
  md,
  lg,
  offset,
  totalCols,
}: {
  theme: CuiTheme;
  xs?: number | 'auto';
  sm?: number | 'auto';
  md?: number | 'auto';
  lg?: number | 'auto';
  offset?:
    | number
    | 'auto'
    | Partial<Record<'xs' | BreakpointKeyType, number | 'auto'>>;
  totalCols: number | Partial<Record<'xs' | BreakpointKeyType, number>>;
}) => {
  const total = parseTotalCols(totalCols);
  const { xsWidth, smWidth, mdWidth, lgWidth } = getResponsiveWidthStyles(
    total,
    { xs, sm, md, lg },
  );
  const { xsOff, smOff, mdOff, lgOff } = getResponsiveOffsetStyles(
    total,
    offset,
  );
  let styles =
    xsWidth || xsOff
      ? `
     ${xsWidth}
     ${xsOff}
  `
      : '';

  if (smWidth || smOff) {
    styles = styles.concat(`
       ${theme.breakpoints.up('sm')} {
         ${smWidth}
         ${smOff}
       }
    `);
  }
  if (mdWidth || mdOff) {
    styles = styles.concat(`
       ${theme.breakpoints.up('md')} {
         ${mdWidth}
         ${mdOff}
       }
    `);
  }
  if (lgWidth || lgOff) {
    styles = styles.concat(`
       ${theme.breakpoints.up('lg')} {
         ${lgWidth}
         ${lgOff}
       }
    `);
  }

  return styles;
};

export default getResponsiveStyled;
