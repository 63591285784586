import React, { forwardRef } from 'react';
import cn from 'classnames';

import parseClassnames from '../../helpers/parseClassnames';
import ResponsiveText from '../../ResponsiveText';

import { getDefaultIconValues, IconItemType } from '../helpers';
import { BUTTON_SIZES, BUTTON_SIZES_WITH_ICON } from '../classnames';

import { PrimaryButtonProps } from './types';

const PrimaryButton = forwardRef<any, PrimaryButtonProps>(
  (
    {
      children,
      label,
      size = 'm',
      as,
      accent,
      white,
      gray,
      darkMode,
      full,
      leftIcon: leftIconProp,
      rightIcon: rightIconProp,
      href,
      className = '',
      labelClassName,
      ...primaryButtonProps
    },
    ref,
  ) => {
    const leftIcon: IconItemType = getDefaultIconValues(
      leftIconProp,
      size === 'xs' ? 16 : 24,
    );
    const rightIcon: IconItemType = getDefaultIconValues(
      rightIconProp,
      size === 'xs' ? 16 : 24,
    );
    let Tag: keyof JSX.IntrinsicElements = 'button';

    if (as) {
      Tag = as;
    } else if (href) {
      Tag = 'a';
    }

    let buttonTypeClassNames = darkMode
      ? 'button-primary-dark'
      : 'button-primary';

    if (gray) {
      buttonTypeClassNames = 'button-primary-gray';
    }
    if (white) {
      buttonTypeClassNames = 'button-primary-white';
    }
    if (accent) {
      buttonTypeClassNames = 'button-primary-accent';
    }

    return (
      <Tag
        className={parseClassnames(
          className,
          cn(
            leftIcon.component || rightIcon.component
              ? BUTTON_SIZES_WITH_ICON[size]
              : BUTTON_SIZES[size],
            buttonTypeClassNames,
            darkMode ? 'button-dark' : 'button-light',
            { 'w-full': full },
          ),
        )}
        href={href}
        ref={ref}
        {...primaryButtonProps}
      >
        {leftIcon.component && (
          <leftIcon.component
            size={leftIcon.size}
            className={cn(
              {
                'fill-current': leftIcon.hasFill,
                'stroke-current': leftIcon.hasStroke,
                'mr-8': label || children,
              },
              'flex-shrink-0',
            )}
            ignoreColor
          />
        )}
        {label ? (
          <ResponsiveText className={labelClassName} text={label} />
        ) : (
          children
        )}
        {rightIcon.component && (
          <rightIcon.component
            size={rightIcon.size}
            className={cn(
              {
                'fill-current': rightIcon.hasFill,
                'stroke-current': rightIcon.hasStroke,
                'ml-8': label || children,
              },
              'flex-shrink-0',
            )}
            ignoreColor
          />
        )}
      </Tag>
    );
  },
);

export default PrimaryButton;
