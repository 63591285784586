import { useState, useRef, useCallback, useMemo, CSSProperties } from 'react';
import useUniqueId from './useUniqueId';
import useEffectAfterMount from './useEffectAfterMount';
import useOpenState from './useOpenState';

type ButtonElementProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  disabled?: boolean;
};

type CollapsibleElementProps<T extends HTMLElement> = React.DetailedHTMLProps<
  React.HTMLAttributes<T>,
  T
> & { refKey?: string };

const noop = () => {};

function getElementHeight(el: React.RefObject<HTMLDivElement>) {
  if (!el || !el.current) {
    return 'auto';
  }
  return el.current.scrollHeight;
}

type AnyFunction = (...args: any) => any;

// Helper function for render props. Sets a function to be called, plus any additional functions passed in
const callAll = (...fns: AnyFunction[]) => (...args: any[]) =>
  fns.forEach(fn => fn && fn(...args));

const defaultTransitionStyles: CSSProperties = {
  transitionDuration: '500ms',
  transitionTimingFunction: 'cubic-bezier(0.250, 0.460, 0.450, 0.940)',
};

function joinTransitionProperties(string?: string) {
  if (string) {
    const styles = ['height'];

    styles.push(...string.split(', '));
    return styles.join(', ');
  }
  return 'height';
}
interface StylesObject {
  expandStyles?: CSSProperties;
  collapseStyles?: CSSProperties;
}

function makeTransitionStyles({
  expandStyles = defaultTransitionStyles,
  collapseStyles = defaultTransitionStyles,
}: StylesObject): StylesObject {
  return {
    expandStyles: {
      ...expandStyles,
      transitionProperty: joinTransitionProperties(
        expandStyles.transitionProperty,
      ),
    },
    collapseStyles: {
      ...collapseStyles,
      transitionProperty: joinTransitionProperties(
        collapseStyles.transitionProperty,
      ),
    },
  };
}

interface CollapsedConfig extends StylesObject {
  isOpen?: boolean;
  defaultOpen?: boolean;
  collapsedHeight?: number;
  onOpenCallback?: () => void;
  onCloseCallback?: () => void;
}

function useCollapse(initialConfig: CollapsedConfig = {}) {
  const uniqueId = useUniqueId();
  const el = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useOpenState(initialConfig);
  const collapsedHeight = `${initialConfig.collapsedHeight || 0}px`;
  const collapsedStyles = useMemo(
    () => ({
      display: collapsedHeight === '0px' ? 'none' : 'block',
      height: collapsedHeight,
      overflow: 'hidden',
    }),
    [collapsedHeight],
  );
  const { expandStyles, collapseStyles } = useMemo(
    () => makeTransitionStyles(initialConfig),
    [initialConfig],
  );
  const [styles, setStyles] = useState<CSSProperties>(
    isOpen ? {} : collapsedStyles,
  );
  const [mountChildren, setMountChildren] = useState(isOpen);

  const toggleOpen = useCallback(() => setOpen(oldOpen => !oldOpen), []);

  useEffectAfterMount(() => {
    let animationId: number | undefined;
    let secondAnimationId: number | undefined;

    if (isOpen) {
      if (initialConfig.onOpenCallback) {
        initialConfig.onOpenCallback();
      }
      animationId = requestAnimationFrame(() => {
        setMountChildren(true);
        setStyles(oldStyles => ({
          ...oldStyles,
          ...expandStyles,
          willChange: 'height',
          display: 'block',
          overflow: 'hidden',
        }));

        secondAnimationId = requestAnimationFrame(() => {
          const height = getElementHeight(el);

          setStyles(oldStyles => ({ ...oldStyles, height }));
        });
      });
    } else {
      if (initialConfig.onCloseCallback) {
        initialConfig.onCloseCallback();
      }
      animationId = requestAnimationFrame(() => {
        const height = getElementHeight(el);

        setStyles(oldStyles => ({
          ...oldStyles,
          ...collapseStyles,
          willChange: 'height',
          height,
        }));

        secondAnimationId = requestAnimationFrame(() => {
          setStyles(oldStyles => ({
            ...oldStyles,
            height: collapsedHeight,
            overflow: 'hidden',
          }));
        });
      });
    }

    return () => {
      if (animationId) cancelAnimationFrame(animationId);
      if (secondAnimationId) cancelAnimationFrame(secondAnimationId);
    };
  }, [isOpen]);

  const handleTransitionEnd = (e: React.TransitionEvent<HTMLDivElement>) => {
    // Sometimes onTransitionEnd is triggered by another transition,
    // such as a nested collapse panel transitioning. But we only
    // want to handle this if this component's element is transitioning
    if (e.target !== el.current) {
      return;
    }

    // The height comparisons below are a final check before completing the transition
    // Sometimes this callback is run even though we've already begun transitioning the other direction
    // The conditions give us the opportunity to bail out, which will prevent the collapsed content from flashing on the screen
    if (isOpen) {
      const height = getElementHeight(el);
      // If the height at the end of the transition matches the height we're animating to,
      // it's safe to clear all style overrides

      if (height === styles.height) {
        setStyles({});
      } else {
        // If the heights don't match, this could be due the height of the content changing mid-transition
        // If that's the case, re-trigger the animation to animate to the new height
        setStyles(oldStyles => ({ ...oldStyles, height }));
      }
      // If the height we should be animating to matches the collapsed height,
      // it's safe to apply the collapsed overrides
    } else if (styles.height === collapsedHeight) {
      setMountChildren(false);
      setStyles(collapsedStyles);
    }
  };

  return {
    getToggleProps(props: ButtonElementProps = {}): ButtonElementProps {
      const { disabled = false, onClick = noop, ...rest } = props;

      return {
        role: 'button',
        id: `react-collapsed-toggle-${uniqueId}`,
        'aria-controls': `react-collapsed-panel-${uniqueId}`,
        'aria-expanded': isOpen ? 'true' : 'false',
        tabIndex: 0,
        ...rest,
        onClick: disabled ? noop : callAll(onClick, toggleOpen),
      };
    },
    getCollapseProps<T extends HTMLElement>(
      props: CollapsibleElementProps<T> = {},
    ): CollapsibleElementProps<T> {
      const {
        style = {},
        onTransitionEnd = noop,
        refKey = 'ref',
        ...rest
      } = props;

      return {
        id: `react-collapsed-panel-${uniqueId}`,
        'aria-hidden': isOpen ? undefined : 'true',
        ...rest,
        [refKey]: el,
        onTransitionEnd: callAll(handleTransitionEnd, onTransitionEnd),
        style: {
          // Default transition duration and timing function, so height will transition
          // when resting and the height of the collapse changes
          ...defaultTransitionStyles,
          // additional styles passed, e.g. getCollapseProps({style: {}})
          ...style,
          // combine any additional transition properties with height
          transitionProperty: joinTransitionProperties(
            style.transitionProperty,
          ),
          // style overrides from state
          ...styles,
        },
      };
    },
    isOpen,
    toggleOpen,
    mountChildren,
  };
}

export default useCollapse;
