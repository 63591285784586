import { screens } from 'crehana-ui/styles/breakpoints';
import { BreakpointKeyType } from 'crehana-ui/styles/types';

function matchMediaQuery(breakpoint: BreakpointKeyType | string) {
  const mediaQuery = `(min-width: ${screens[breakpoint] || breakpoint})`;
  const nativeMediaQueryList = window.matchMedia(mediaQuery);

  return nativeMediaQueryList;
}

export default matchMediaQuery;
