import React from 'react';
import { IconProps } from './types';

const Linkedin: React.FC<IconProps> = ({
  className,
  color = '#181B32',
  size = 32,
  ignoreColor,
}) => (
  <svg
    className={className}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={0.9375 * size}
    height={size}
    viewBox="0 0 30 32"
  >
    <path
      fill={!ignoreColor ? color : undefined}
      d="M15.858 14.434v-0.050c-0.009 0.016-0.020 0.034-0.030 0.050h0.030zM27.31 0.001h-25.131c-1.203 0-2.179 1.027-2.179 2.293v27.412c0 1.266 0.976 2.294 2.179 2.294h25.13c1.204 0 2.18-1.028 2.18-2.294v-27.412c0-1.267-0.976-2.293-2.18-2.293zM8.939 26.787h-4.454v-14.449h4.454v14.449zM6.712 10.366h-0.030c-1.494 0-2.461-1.11-2.461-2.497 0-1.417 0.997-2.496 2.52-2.496s2.462 1.079 2.491 2.496c0 1.387-0.967 2.497-2.52 2.497zM24.999 26.787h-4.453v-7.731c0-1.942-0.644-3.266-2.256-3.266-1.23 0-1.963 0.893-2.286 1.756-0.118 0.309-0.146 0.739-0.146 1.172v8.069h-4.454s0.058-13.093 0-14.449h4.454v2.046c0.592-0.984 1.651-2.385 4.015-2.385 2.93 0 5.127 2.065 5.127 6.504v8.285z"
    />
  </svg>
);

export default Linkedin;
