import React from 'react';
import { IconProps } from './types';

const Twitter: React.FC<IconProps> = ({
  className,
  color = '#181B32',
  size = 32,
  ignoreColor,
}) => (
  <svg
    className={className}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={1.25 * size}
    height={size}
    viewBox="0 0 40 32"
  >
    <path
      fill={!ignoreColor ? color : undefined}
      d="M39.536 3.787c-1.357 0.616-2.931 1.064-4.581 1.265 1.599-1.002 2.86-2.555 3.473-4.405-1.466 0.835-3.192 1.516-5.030 1.886-1.588-1.568-3.693-2.556-6.029-2.556-4.477 0-8.107 3.63-8.107 8.107 0 0.65 0.077 1.283 0.221 1.889-6.763-0.398-12.705-3.596-16.699-8.456-0.722 1.124-1.13 2.526-1.13 4.023 0 2.794 1.421 5.257 3.579 6.705-1.326-0.025-2.585-0.394-3.685-1.013 0.040 0.055 0.040 0.088 0.040 0.123 0.007 3.902 2.776 7.156 6.455 7.912-0.587 0.189-1.322 0.293-2.081     0.293-0.542 0-1.071-0.053-1.583-0.153 1.126 3.247 4.1 5.554 7.621 5.619-2.733 2.157-6.233 3.459-10.038 3.459-0.010 0-0.020 0-0.030-0-0.68 0-1.352-0.041-2.012-0.121 3.589 2.284 7.88 3.637 12.487 3.637 0.009 0 0.018 0 0.028-0 14.919 0 23.077-12.31 23.077-22.986 0-0.35-0.007-0.699-0.023-1.045 1.594-1.156 2.936-2.542 4.010-4.125z"
    />
  </svg>
);

export default Twitter;
