import React from 'react';
import { IconProps } from './types';

const ArrowBottom: React.FC<IconProps> = ({
  className,
  color = '#181B32',
  size = 32,
  ignoreColor,
}) => (
  <svg
    className={className}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={1 * size}
    height={size}
    viewBox="0 0 32 32"
  >
    <path
      fill={!ignoreColor ? color : undefined}
      d="M16.050 20.45l-12.8-12.85c-0.75-0.75-1.95-0.75-2.7 0-0.35 0.35-0.55 0.8-0.55 1.3v0c0 0.5 0.2 1 0.55 1.35l14.1 14.15c0.7 0.75 1.9 0.75 2.65 0.050 0 0 0 0 0.050-0.050l14.1-14.15c0.35-0.35 0.55-0.85 0.55-1.35v0c0-1.050-0.85-1.9-1.9-1.9-0.5 0-1 0.2-1.35 0.55l-12.7 12.9z"
    />
  </svg>
);

export default ArrowBottom;
