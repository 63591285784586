import { useEffect, useState } from 'react';

// Unique ID implementation borrowed from React UI :)
// https://github.com/reach/reach-ui/blob/6e9dbcf716d5c9a3420e062e5bac1ac4671d01cb/packages/auto-id/src/index.js
let idCounter = 0;
const genId = () => {
  idCounter += 1;
  return idCounter;
};

function useUniqueId() {
  const [id, setId] = useState<number | null>(null);

  useEffect(() => setId(genId()), []);
  return id;
}

export default useUniqueId;
