import React, { FC } from 'react';

import { useTranslation } from 'crehana-i18n';
import { Container } from 'crehana-ui/GridV2';

import { CountryPrefix } from 'Jsx/global';

import TopSection from './sections/TopSection';
import BottomSection from './sections/BottomSection';

import localeEs from './i18n/localeEs';
import localePt from './i18n/localePt';
import { MAIN_FOOTER_PAGE_KEY } from './constants';

export interface MainFooterProps {
  countryPrefix: CountryPrefix;
  isDark?: boolean;
}

const MainFooter: FC<MainFooterProps> = ({ countryPrefix, isDark }) => {
  const { addResourceBundle } = useTranslation();

  addResourceBundle({
    es: { [MAIN_FOOTER_PAGE_KEY]: localeEs.es },
    pt: { [MAIN_FOOTER_PAGE_KEY]: localePt.pt },
  });

  if (process.env.NODE_ENV === 'development') {
    if (!countryPrefix) {
      console.warn(
        'If you are in a page without internationalization(i18n) just ignore this warn. Hey! You are not passing the <countryPrefix> prop to the MainFooter component. We need this to create the urls!',
      );
    }
  }

  return (
    <footer
      id="main-footer"
      className={isDark ? 'bg-base-main-80' : 'bg-transparent'}
    >
      <Container>
        <TopSection countryPrefix={countryPrefix} isDark={isDark} />
        <BottomSection isDark={isDark} />
      </Container>
    </footer>
  );
};

export default MainFooter;
