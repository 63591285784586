import { v4 as uuidv4 } from 'uuid';
import * as cookies from '../cookies';

export const DISTINCT_ID_KEY = 'session_distinct_id';

function getUserDistinctId(): {
  // distinctId is the uuid string.
  distinctId: string;
  // wasRecentlyCreated is a boolean that will be true if it has created a new token and it will be false if the token already exists and come from localstorage.
  wasRecentlyCreated: boolean;
} {
  const distinctId = cookies.getAll()[DISTINCT_ID_KEY];

  if (distinctId) {
    return { distinctId, wasRecentlyCreated: false };
  }

  /**
   * We must get the user uuid from cookies.
   * If there is not cookie, we must create a new one and store it in localStorage
   * Remember to delete this localStorage key after the user log-out
   */

  const newDistinctId = uuidv4();

  cookies.setCookie(DISTINCT_ID_KEY, newDistinctId, 365);
  window.distinctUserIdWasRecentlyCreated = true;

  return { distinctId: newDistinctId, wasRecentlyCreated: true };
}

export default getUserDistinctId;
