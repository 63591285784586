import * as React from 'react';
import PrimaryButton from 'crehana-ui/Buttons/PrimaryButton';
import getCurrentLanguageKey from 'Jsx/Utils/getCurrentLanguageKey';

const LOCALES = {
  es: {
    INTERNET_CONNECTION_ERROR: 'Ocurrió un error de conexión',
    RELOAD_PAGE: 'Recargar la página',
  },
  pt: {
    INTERNET_CONNECTION_ERROR: 'Ocorreu um erro de conexão',
    RELOAD_PAGE: 'Recarregue a página',
  },
};

const STRINGS = LOCALES[getCurrentLanguageKey()];

type Props = {
  Loader?: React.ReactElement<{}>;
  ErrorFallback?: React.ReactElement<{}>;
  renderDefaultErrorFallback?: boolean;
  loading?: boolean;
  module(): Promise<any>;
  render?(component?: React.ComponentType);
  [index: string]: any;
  name?: string;
  delay?: number;
};

type State = {
  LoadableComponent?: React.ComponentType<any>;
  pastDelay: boolean;
  error: boolean;
};

class Loadable<TProps = {}> extends React.Component<TProps & Props, State> {
  static defaultProps = {
    loading: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      LoadableComponent: undefined,
      pastDelay: typeof this.props.delay === 'undefined',
      error: false,
    };
  }

  componentDidMount() {
    const { delay } = this.props;

    if (delay) {
      setTimeout(() => {
        this.setState({ pastDelay: true });
      }, delay);
    }

    this.props
      .module()
      .then(Component => {
        this.setState({ LoadableComponent: Component.default });
      })
      .catch(e => {
        this.setState({ error: true });
        // TODO: send the error to mixpanel
        console.log('Error to load the chunk', e);
      });
  }

  render() {
    const { LoadableComponent, pastDelay, error } = this.state;
    // just spread module to not passed it with the rest of props
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
      render,
      Loader,
      ErrorFallback,
      renderDefaultErrorFallback,
      loading,
      module,
      ...rest
    } = this.props;

    if (error) {
      if (ErrorFallback || renderDefaultErrorFallback) {
        return (
          ErrorFallback || (
            <div className="w-full bg-base-lighter-16 flex flex-col items-center text-center p-16 sm:p-32">
              <h4 className="font-subtitle2 mb-16">
                {STRINGS.INTERNET_CONNECTION_ERROR}
              </h4>
              <PrimaryButton
                className="w-full sm:w-auto"
                label={STRINGS.RELOAD_PAGE}
                onClick={() => {
                  window.location.reload();
                }}
              />
            </div>
          )
        );
      }
      return null;
    }

    if (!pastDelay || loading || !LoadableComponent) {
      return Loader || null;
    }

    if (LoadableComponent) {
      if (render) {
        return render(LoadableComponent);
      }

      return <LoadableComponent {...rest} />;
    }

    return null;
  }
}

export default Loadable;
