import { BreakpointKeyType } from '../../../styles/types';
import { Gutter } from '../types';

const getMarginClass = (
  gutter: Gutter,
  className?: string,
  nowrap?: boolean,
  bp?: BreakpointKeyType,
) => {
  const value = gutter / 2;

  // Apply full margin if the element doesn't have any custom margins and nowrap is false
  if (!/m(x|l|r|y|t|b)?-/.test(className || '') && !nowrap) {
    return `${bp ? `${bp}:` : ''}-m-${value}`;
  }

  const hasLeftMargin = className?.includes('ml-');
  const hasRightMargin = className?.includes('mr-');
  const hasHorizontalMargin =
    className?.includes('mx-') || (hasLeftMargin && hasRightMargin);
  const hasTopMargin = className?.includes('mt-');
  const hasBottomMargin = className?.includes('mb-');
  const hasVerticalMargin =
    className?.includes('my-') || (hasTopMargin && hasBottomMargin);
  const hasFullMargin =
    className?.includes('m-') || (hasHorizontalMargin && hasVerticalMargin);

  // Ignore gutter classes if custom margin is provided for every side
  if (hasFullMargin) {
    return '';
  }

  // Apply only horizontal margin if it has custom vertical margins or nowrap is true
  if (nowrap || hasVerticalMargin) {
    return `${bp ? `${bp}:` : ''}-mx-${value}`;
  }

  // Apply only vertical margin if it has custom horizontal margins
  if (hasHorizontalMargin) {
    return `${bp ? `${bp}:` : ''}-my-${value}`;
  }

  // Apply gutter margins to each side that doesn't have a custom margin
  let marginClasses = !hasTopMargin ? `${bp ? `${bp}:` : ''}-mt-${value} ` : '';

  if (!hasRightMargin) {
    marginClasses = `${marginClasses}${bp ? `${bp}:` : ''}-mr-${value} `;
  }
  if (!hasBottomMargin) {
    marginClasses = `${marginClasses}${bp ? `${bp}:` : ''}-mb-${value} `;
  }
  if (!hasLeftMargin) {
    marginClasses = `${marginClasses}${bp ? `${bp}:` : ''}-ml-${value} `;
  }

  return marginClasses.replace(/ $/, '');
};

export default getMarginClass;
