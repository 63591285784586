import { useContext } from 'react';

import { AuthContext, Me as MeType } from 'Jsx/ContextApis/AuthContext';

export type Me = MeType;

const useMe = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useMe must be used within an AuthProvider');
  }

  return context.data;
};

export default useMe;
