export default {
  pt: {
    COMPANY_TITLE: 'Firma',
    COMPANY_HELP_CENTER: 'Central de Ajuda',
    COMPANY_ABOUT: 'Sobre Crehana',
    COMPANY_HIRING: 'Estamos contratando!',
    COMPANY_CREHANA_FOR_BUSINESS: 'Crehana para empresas',

    COMMUNITY_TITLE: 'Comunidade',
    COMMUNITY_SCHOLARSHIPS: 'Bolsas',
    COMMUNITY_STORIES: 'Histórias de sucesso',
    COMMUNITY_PROJECTS: 'Galeria de projetos',
    COMMUNITY_RESOURCES: 'Recursos',
    COMMUNITY_BLOG: 'Blog',
    COMMUTITY_PREMIUM: 'Torne-se Premium',

    HELP_TITLE: 'Links de ajuda',
    HELP_PRIVACY: 'Políticas de Privacidade',
    HELP_COOKIES: 'Política de cookies',
    HELP_ARCO: 'Solicitação ARCO',
    HELP_CANCELLATION: 'Política de cancelación',
    HELP_TERMS: 'Termos e Condições',
    HELP_CLAIMS_BOOK: 'Libro de reclamaciones',

    APPS_TITLE: 'Baixe nosso app',

    RIGHTS_RESERVED: 'Todos os direitos reservados',
  },
};
