import isPast from 'date-fns/is_past';
/* eslint-disable no-restricted-globals, no-restricted-properties */
/**
 * Here we have our custom localStorage method to prevent errors from localStorage on computers with no disk space left.
 */
const customLocalStorage = {
  setItem: (keyName: string, keyValue: string | number) => {
    try {
      return window.localStorage.setItem(keyName, keyValue);
    } catch (error) {
      console.log('Error to set item to localStorage', error);
      return null;
    }
  },
  getItem: (keyName: string) => {
    try {
      return window.localStorage.getItem(keyName);
    } catch (error) {
      console.log('Error to get item from localStorage', error);

      return null;
    }
  },
  removeItem: (keyName: string) => {
    try {
      return window.localStorage.removeItem(keyName);
    } catch (error) {
      console.log('Error to remove item from localStorage', error);

      return undefined;
    }
  },
  clear: () => {
    try {
      return window.localStorage.clear();
    } catch (error) {
      console.log('Error to clear all items from localStorage', error);

      return null;
    }
  },
  clearExpiredKeys: () => {
    const expirableLocalStorageKeys = ['CREHANA__USER_JUST_LOGGED'];

    try {
      expirableLocalStorageKeys.forEach(localStorageKey => {
        if (
          window.localStorage.getItem(localStorageKey) &&
          isPast(window.localStorage.getItem(localStorageKey) as string)
        ) {
          window.localStorage.removeItem(localStorageKey);
        }
      });
    } catch (error) {
      console.error('Clear local storage expired keys');
    }
  },
};

export default customLocalStorage;
