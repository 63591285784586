import { useState, useEffect } from 'react';

function useDebug(defaultValue?: boolean, ignoreKeyboard?: boolean) {
  const [debug, setDebug] = useState<boolean>(!!defaultValue);

  useEffect(() => {
    setDebug(!!defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    // Little "hack" to allow logged in staff users to toggle debug mode on production
    // @ts-expect-error (Property '__INITIAL_STATE__' does not exist on type 'Window $ typeof globalThis')
    const isCrehanaStaff = window.__INITIAL_STATE__?.user?.isStaff;
    const shouldAddListener =
      typeof document !== 'undefined' &&
      !ignoreKeyboard &&
      (process.env.NODE_ENV !== 'production' ||
        process.env.ENABLE_CREHUI_DEBUG ||
        isCrehanaStaff);

    const listener = (event: HTMLElementEventMap['keydown']) => {
      if (
        event.ctrlKey &&
        event.shiftKey &&
        (event.code === 'Digit3' || event.code === 'Numpad3')
      ) {
        setDebug(prevDebug => !prevDebug);
      }
    };

    if (shouldAddListener) {
      document.addEventListener('keydown', listener);
    }

    return () => {
      if (shouldAddListener) {
        document.removeEventListener('keydown', listener);
      }
    };
  }, [ignoreKeyboard]);

  return debug;
}

export default useDebug;
