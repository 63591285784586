import { BreakpointKeyType } from '../../../styles/types';
import { Gutter } from '../types';

const getPaddingClass = (
  gutter: Gutter,
  className?: string,
  nowrap?: boolean,
  bp?: BreakpointKeyType,
) => {
  const value = gutter / 2;

  // Apply full padding if the element doesn't have any custom paddings and nowrap is false
  if (!/p(x|l|r|y|t|b)?-/.test(className || '') && !nowrap) {
    return `${bp ? `${bp}:` : ''}p-${value}`;
  }

  const hasLeftPadding = className?.includes('pl-');
  const hasRightPadding = className?.includes('pr-');
  const hasHorizontalPadding =
    className?.includes('px-') || (hasLeftPadding && hasRightPadding);
  const hasTopPadding = className?.includes('pt-');
  const hasBottomPadding = className?.includes('pb-');
  const hasVerticalPadding =
    className?.includes('py-') || (hasTopPadding && hasBottomPadding);
  const hasFullPadding =
    className?.includes('p-') || (hasHorizontalPadding && hasVerticalPadding);

  // Ignore gutter classes if custom padding is provided for every side
  if (hasFullPadding) {
    return '';
  }

  // Apply only horizontal padding if it has custom vertical paddings or nowrap is true
  if (nowrap || hasVerticalPadding) {
    return `${bp ? `${bp}:` : ''}px-${value}`;
  }

  // Apply only vertical padding if it has custom horizontal paddings
  if (hasHorizontalPadding) {
    return `${bp ? `${bp}:` : ''}py-${value}`;
  }

  // Apply gutter paddings to each side that doesn't have a custom padding
  let paddingClasses = !hasTopPadding
    ? `${bp ? `${bp}:` : ''}pt-${value} `
    : '';

  if (!hasRightPadding) {
    paddingClasses = `${paddingClasses}${bp ? `${bp}:` : ''}pr-${value} `;
  }
  if (!hasBottomPadding) {
    paddingClasses = `${paddingClasses}${bp ? `${bp}:` : ''}pb-${value} `;
  }
  if (!hasLeftPadding) {
    paddingClasses = `${paddingClasses}${bp ? `${bp}:` : ''}pl-${value} `;
  }

  return paddingClasses.replace(/ $/, '');
};

export default getPaddingClass;
