import { CuiTheme } from '../../styles/types';
import { ColProps } from '../Default/types';
import getResponsiveStyled from './getResponsiveStyled';

const getResponsiveStyledDefault = ({
  theme,
  xs,
  sm,
  md,
  lg,
  offset,
  totalCols = 12,
}: { theme: CuiTheme } & ColProps) =>
  getResponsiveStyled({
    theme,
    xs,
    sm,
    md,
    lg,
    offset,
    totalCols,
  });

export default getResponsiveStyledDefault;
