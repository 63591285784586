import { useEffect, useRef, EffectCallback, DependencyList } from 'react';

function useEffectAfterMount(
  cb: EffectCallback,
  dependencies?: DependencyList,
) {
  const justMounted = useRef(true);

  useEffect(() => {
    if (!justMounted.current) {
      return cb();
    }
    justMounted.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

export default useEffectAfterMount;
