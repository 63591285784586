// DON'T EDIT THIS FILE!! IT'S AUTO-GENERATED USING GRAPHQL-CODE-GENERATOR CLI
/* eslint-disable camelcase */
import * as Types from '../../../../Types/__generated__/types';

import gql from 'graphql-tag.macro';
import * as ApolloReactCommon from 'react-apollo';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type AuthContextCreateUserMutationMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  password: Types.Scalars['String'];
  nextUrl: Types.Scalars['String'];
  referFriendId?: Types.Maybe<Types.Scalars['ID']>;
}>;

export type AuthContextCreateUserMutationMutation = {
  __typename?: 'PublicMutations';
  createUser?: Types.Maybe<{
    __typename?: 'CreateUserPayload';
    isB2B?: Types.Maybe<boolean>;
    redirect?: Types.Maybe<string>;
    success?: Types.Maybe<boolean>;
    user?: Types.Maybe<{
      __typename?: 'UserNode';
      originalId?: Types.Maybe<number>;
    }>;
    messages?: Types.Maybe<
      Array<
        Types.Maybe<{
          __typename?: 'MessageNode';
          field?: Types.Maybe<string>;
          message?: Types.Maybe<string>;
        }>
      >
    >;
  }>;
};

export const AuthContextCreateUserMutationDocument = gql`
  mutation AuthContextCreateUserMutation(
    $email: String!
    $password: String!
    $nextUrl: String!
    $referFriendId: ID
  ) {
    createUser(
      input: {
        email: $email
        password: $password
        nextUrl: $nextUrl
        referFriendId: $referFriendId
      }
    ) {
      user {
        originalId
      }
      messages {
        field
        message
      }
      isB2B
      redirect
      success
    }
  }
`;

/**
 * __useAuthContextCreateUserMutationMutation__
 *
 * To run a mutation, you first call `useAuthContextCreateUserMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthContextCreateUserMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authContextCreateUserMutationMutation, { data, loading, error }] = useAuthContextCreateUserMutationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      nextUrl: // value for 'nextUrl'
 *      referFriendId: // value for 'referFriendId'
 *   },
 * });
 */
export function useAuthContextCreateUserMutationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AuthContextCreateUserMutationMutation,
    AuthContextCreateUserMutationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AuthContextCreateUserMutationMutation,
    AuthContextCreateUserMutationMutationVariables
  >(AuthContextCreateUserMutationDocument, baseOptions);
}
export type AuthContextCreateUserMutationMutationHookResult = ReturnType<
  typeof useAuthContextCreateUserMutationMutation
>;
export type AuthContextCreateUserMutationMutationResult = ApolloReactCommon.MutationResult<
  AuthContextCreateUserMutationMutation
>;
export type AuthContextCreateUserMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AuthContextCreateUserMutationMutation,
  AuthContextCreateUserMutationMutationVariables
>;
