import React, { FC } from 'react';

import { IconProps } from 'crehana-icons/types';

interface SocialLinkProps {
  link: string;
  icon: React.ComponentType<IconProps>;
  size?: number;
}

const SocialLink: FC<SocialLinkProps> = ({ link, icon: Icon, size = 18 }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="text-inherit px-12"
    >
      <Icon size={size} ignoreColor className=" fill-current" />
    </a>
  );
};

export default SocialLink;
