import * as React from 'react';
import { ApolloQueryResult } from 'apollo-client';
import { ExecutionResult } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';

import customLocalStorage from 'Utils/customLocalStorage';
import { DISTINCT_ID_KEY } from 'Utils/getUserDistinctId';
import { deleteCookie } from 'Jsx/Utils/cookies';
import mixpanel from 'Jsx/Utils/mixpanelTracking';

import { useSyncUserSession } from './hooks';

import ME_QUERY from './graphql/me.graphql';
import {
  MeAuthContext as MeAuthContextResponse,
  MeAuthContext_me as _MeObject,
} from './graphql/types/MeAuthContext';
import {
  useAuthContextCreateUserMutationMutation,
  AuthContextCreateUserMutationMutationVariables,
  AuthContextCreateUserMutationMutation,
} from './graphql/__generated__/CreateUser.gcg';
import {
  useAuthContextLoginUserMutationMutation,
  AuthContextLoginUserMutationMutationVariables,
  AuthContextLoginUserMutationMutation,
} from './graphql/__generated__/LoginUser.gcg';

import { LOCALSTORAGE_USER_SESSION_KEY } from './constants';

export type MeObject = _MeObject;

export type Me = MeObject | null;

export type State = {
  data: {
    me?: Me;
    loading: boolean;
    error?: any;
    refetch: (variables?: {}) => Promise<
      ApolloQueryResult<MeAuthContextResponse>
    >;
    updateMe: (
      mapFn: (
        previousQueryResult: MeAuthContextResponse,
        options: {
          variables?: {};
        },
      ) => MeAuthContextResponse,
    ) => void;
  };
  logout: () => void;
  login: (
    variables: AuthContextLoginUserMutationMutationVariables,
  ) => Promise<ExecutionResult<AuthContextLoginUserMutationMutation>>;
  register: (
    variables: AuthContextCreateUserMutationMutationVariables,
  ) => Promise<ExecutionResult<AuthContextCreateUserMutationMutation>>;
};

export const AuthContext = React.createContext<State | undefined>(undefined);

export const AuthProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [
    hasFinishedToProcessData,
    setHasFinishedToProcessData,
  ] = React.useState(false);

  const { data, loading, error, refetch, updateQuery } = useQuery<
    MeAuthContextResponse,
    {}
  >(ME_QUERY, { notifyOnNetworkStatusChange: true });
  const [loginUser] = useAuthContextLoginUserMutationMutation();
  const [registerUser] = useAuthContextCreateUserMutationMutation();

  React.useEffect(() => {
    // used in blog pages to refecth the user data after the user registration/login is completed.
    // With this we don't need to reload the entire page to see the changes of the user in the main menu
    window.getUserData = () => {
      refetch();
    };
  }, []);

  React.useEffect(() => {
    if (!loading && data) {
      if (data.me) {
        customLocalStorage.setItem(
          LOCALSTORAGE_USER_SESSION_KEY,
          data.me.originalId as number,
        );
        // set the user in sentry
        if (window.Sentry) {
          window.Sentry.configureScope(function(scope) {
            scope.setUser({
              email: data.me!.email,
              id: data.me!.originalId || undefined,
            });
          });
        }

        // custom data used in tracking services
        const customData = {
          segment: data.me.segmentationKey,
          username: data.me.username,
        };

        customLocalStorage.setItem(
          'creh-tracking-data',
          JSON.stringify(customData),
        );

        // redirect to homepage if the user is logged and we are in homepage
        if (window.PAGE_KEY === 'HOMEPAGE') {
          const url = data.me.organization
            ? `/org/${data.me.organization.slug}/`
            : '/home/';

          window.location.href = url;
        }
      } else {
        customLocalStorage.removeItem(LOCALSTORAGE_USER_SESSION_KEY);
        if (window.Sentry) {
          window.Sentry.configureScope(function(scope) {
            scope.setUser({ email: null });
          });
        }
      }

      setHasFinishedToProcessData(true);
    }
  }, [loading, data]);

  useSyncUserSession({
    startSync: hasFinishedToProcessData,
  });

  const logout = () => {
    customLocalStorage.removeItem('creh-tracking-data');
    // TODO: review if user key is being used in old applications
    customLocalStorage.removeItem('user');

    // We remove this variable to allow updates popup to show in future sessions in admindashboard
    customLocalStorage.removeItem('updates-admindashboard-popup-shown');

    // For more info check Jsx/Utils/getUserDistinctId.ts
    // We need to clear the uuid when the user log-out
    // the anonymous user must get a new uuid
    deleteCookie(DISTINCT_ID_KEY);
    customLocalStorage.removeItem(LOCALSTORAGE_USER_SESSION_KEY);

    window.location.href = '/logout/';
  };

  const login = async (
    variables: AuthContextLoginUserMutationMutationVariables,
  ) => {
    const res = await loginUser({ variables });

    if (res.data?.loginUser?.success) {
      mixpanel.track(mixpanel.EVENTS.LOGIN, {
        provider: 'email',
      });
    }
    return res;
  };

  const register = async (
    variables: AuthContextCreateUserMutationMutationVariables,
  ) => {
    const res = await registerUser({ variables });

    if (res.data?.createUser?.success) {
      mixpanel.track(mixpanel.EVENTS.REGISTER, {
        provider: 'email',
      });
    }
    return res;
  };

  return (
    <AuthContext.Provider
      value={{
        data: {
          me: data?.me,
          loading,
          error,
          refetch,
          updateMe: updateQuery,
        },
        logout,
        login,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useMe = () => {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useMe must be used within an AuthProvider');
  }

  return context.data;
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
