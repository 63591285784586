export const BUTTON = 'button-base';

export const BUTTON_SIZES = {
  xs: 'button-xs',
  s: 'button-s',
  m: 'button-m',
  l: 'button-l',
};

export const BUTTON_SIZES_WITH_ICON = {
  xs: 'button-xs-with-icon',
  s: 'button-s-with-icon',
  m: 'button-m-with-icon',
  l: 'button-l-with-icon',
};
