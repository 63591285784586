import { useContext, useCallback, ReactNode } from 'react';

import { CrehanaI18nContext } from './Context';
import { I18nTranslation, TConfig, FormatterFn } from './types';
import { toJsx } from './formatters';
import { pipe } from './utils';

interface ExtendedTConfig extends TConfig {
  formatter?: FormatterFn;
  formatters?: FormatterFn[];
}

export interface CrehanaTFunction<T = string> {
  (key: T): string;
  (key: T, defaultValue: string): string;
  (key: T, config: TConfig): string;
  (key: T, withFormatterConfig: ExtendedTConfig): ReactNode;
  (key: T, defaultValue: string, config?: TConfig): string;
}

export interface UseTranslationResult<T, U> {
  t: CrehanaTFunction<T>;
  languageKey: U;
  debug: boolean;
  addResourceBundle: (bundle: I18nTranslation) => void;
}

const KNOWN_KEYS = [
  'defaultValue',
  'namespace',
  'interpolation',
  'fallbackNs',
  'formatter',
  // 'count',
];

function parseExtraConfig(ns?: string, config?: ExtendedTConfig): TConfig {
  let actualConfig: TConfig = {};

  if (ns) {
    actualConfig.namespace = ns;
  }

  if (config) {
    actualConfig = { ...actualConfig, ...config };
    // Get all the keys that are not part of the API
    const keys = Object.keys(config).filter(k => !KNOWN_KEYS.includes(k));

    keys.forEach(k => {
      if (process.env.NODE_ENV === 'development') {
        if (k !== 'count' && (config as any)[k]) {
          console.warn(
            '%c WARNING:\n',
            'font-size: 18px',
            'crehana-i18n no longer supports unknown keys as interpolation values',
            'please update use the interpolation property in the config object',
            'this was previously available as part of the migration from react-i18next',
          );
        }
      }
      actualConfig = {
        ...actualConfig,
        interpolation: {
          count: actualConfig.count,
          ...actualConfig.interpolation,
          [k]: (config as any)[k],
        },
      };
    });
  }

  return actualConfig;
}

function getFormatters(config?: ExtendedTConfig): FormatterFn | undefined {
  if (!config) return;

  const formatters = [];

  if (config.formatter) {
    formatters.push(config.formatter);
  }
  if (config.formatters?.length) {
    config.formatters.forEach(f => {
      if (f) {
        formatters.push(f);
      }
    });
  }

  if (!formatters.length) return;

  return pipe(...formatters);
}

/**
 * @param namespace - Optional namespace useful to be certain about which key
 * should be selected (in case there's more than one key with the same name).
 *
 * If you pass a `namespace` through the `t` function, it'll have higher priority.
 */
export function useTranslation<T extends string, U extends string = string>(
  namespace?: string,
): UseTranslationResult<T, U> {
  const context = useContext(CrehanaI18nContext);

  const tFunction = useCallback(
    (
      key: T,
      defaultValueOrC?: string | ExtendedTConfig,
      config?: ExtendedTConfig,
    ) => {
      const conf =
        config ||
        (typeof defaultValueOrC !== 'string' ? defaultValueOrC : undefined);

      const formatter = getFormatters(conf);

      // If we're not within the context avoid breaking the ui
      if (!context) {
        if (typeof defaultValueOrC === 'string') {
          if (formatter) return toJsx(formatter(defaultValueOrC));
          return defaultValueOrC;
        }
        if (defaultValueOrC?.defaultValue) {
          if (formatter) return toJsx(formatter(defaultValueOrC.defaultValue));
          return defaultValueOrC.defaultValue;
        }
        return key;
      }
      const { t } = context!;

      if (!defaultValueOrC && !namespace) {
        if (formatter) return toJsx(formatter(t(key)));
        return t(key);
      }

      if (typeof defaultValueOrC === 'string') {
        const translation = t(
          key,
          parseExtraConfig(namespace, {
            ...config,
            defaultValue: defaultValueOrC,
          }),
        );

        if (formatter) return toJsx(formatter(translation));
        return translation;
      }

      const translation = t(key, parseExtraConfig(namespace, defaultValueOrC));

      if (formatter) return toJsx(formatter(translation));
      return translation;
    },
    [context, namespace],
  ) as CrehanaTFunction<T>;

  const addResourceBundle = (bundle: I18nTranslation) => {
    if (context) {
      context.addResourceBundle(bundle);
    }
  };

  return {
    t: tFunction,
    languageKey: (context?.languageKey ?? '') as U,
    debug: context?.debug ?? false,
    addResourceBundle,
  };
}
