import * as React from 'react';

import customLocalStorage from 'Utils/customLocalStorage';

import { MeAuthContext_me as _MeObject } from './graphql/types/MeAuthContext';
import { LOCALSTORAGE_USER_SESSION_KEY } from './constants';

type UseSessionAcrossTabsOptions = {
  startSync: boolean;
};

export const useSyncUserSession = ({
  startSync,
}: UseSessionAcrossTabsOptions) => {
  const useSessionRef = React.useRef<string | null>(null);

  React.useEffect(() => {
    useSessionRef.current = customLocalStorage.getItem(
      LOCALSTORAGE_USER_SESSION_KEY,
    );

    const onVisibilityChangeHandler = () => {
      if (document.visibilityState === 'visible') {
        const userSession = customLocalStorage.getItem(
          LOCALSTORAGE_USER_SESSION_KEY,
        );

        if (useSessionRef.current !== userSession) {
          // If the user session has changed, reload the page to update the session
          window.location.reload();
        }
      } else {
        console.log('tab hidden');
      }
    };

    if (startSync) {
      document.addEventListener('visibilitychange', onVisibilityChangeHandler);
      window.onstorage = function() {
        // https://stackoverflow.com/questions/24077117/localstorage-in-win8-1-ie11-does-not-synchronize
        // Leave this empty
        // workaround for IE11 localstorage synchronization problem.
        // the event
      };
    }

    return () => {
      document.removeEventListener(
        'visibilitychange',
        onVisibilityChangeHandler,
      );
    };
  }, [startSync]);
};
