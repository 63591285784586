import React from 'react';
import { IconProps } from './types';

const Instagram: React.FC<IconProps> = ({
  className,
  color = '#181B32',
  size = 32,
  ignoreColor,
}) => (
  <svg
    className={className}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={1 * size}
    height={size}
    viewBox="0 0 32 32"
  >
    <path
      fill={!ignoreColor ? color : undefined}
      d="M15.836 7.678c-4.493 0-8.147 3.694-8.147 8.233s3.655 8.234 8.147 8.234 8.148-3.694 8.148-8.234-3.655-8.233-8.148-8.233zM15.836 21.198c-2.885 0-5.232-2.372-5.232-5.287s2.347-5.287 5.232-5.287 5.233 2.372 5.233 5.287-2.347 5.287-5.233 5.287zM24.291 5.608c-0.006-0-0.012-0-0.019-0-1.032 0-1.869 0.837-1.869 1.869s0.837 1.869 1.869 1.869c1.032 0 1.869-0.837 1.869-1.869 0-0 0-0 0-0.001 0-0.003 0-0.006 0-0.009 0-1.024-0.827-1.854-1.85-1.859zM29.118 2.62c-1.683-1.714-4.002-2.619-6.706-2.619h-13.153c-5.538 0-9.259 3.759-9.259 9.355v13.217c0 2.796 0.922 5.179 2.666 6.889 1.693 1.661 3.998 2.539 6.666 2.539h13.006c2.719 0 5.044-0.876 6.723-2.533 1.708-1.684 2.61-4.044 2.61-6.823v-13.29c0-2.706-0.884-5.036-2.554-6.736zM28.756 22.647c0 1.967-0.599 3.598-1.732 4.715-1.123 1.109-2.744 1.695-4.685 1.695h-13.006c-1.894 0-3.497-0.59-4.636-1.707-1.165-1.142-1.781-2.795-1.781-4.776v-13.217c0-1.954 0.59-3.579 1.707-4.702 1.112-1.117 2.715-1.708 4.637-1.708h13.152c1.91 0 3.513 0.604 4.637 1.749 1.117 1.137 1.707 2.749 1.707 4.661v13.29z"
    />
  </svg>
);

export default Instagram;
